import _ from "lodash/fp";
import {IconButton} from "@mui/material";
import {ArrowDropDown, ArrowDropUp} from "@material-ui/icons";
import React from "react";


const PairingDayRecord = ({index, groupSize, pairing, onSwap = _.noop}) => {

    const MoveDownButton = ({role,id,hide}) => {
        return (
            <IconButton onClick={() => onSwap('down',role,id)} style={{padding: '0', visibility: hide ? 'hidden' : 'visible'}}>
                <ArrowDropDown/>
            </IconButton>
        )
    }
    const MoveUpButton = ({role,id,hide}) => {
        return (
            <IconButton onClick={() => onSwap('up',role,id)} style={{padding: '0', visibility: hide ? 'hidden' : 'visible'}}>
                <ArrowDropUp/>
            </IconButton>
        )
    }
    return (
        <div className='draft-pairing-day-grid__row-grid'>
            <div className='draft-pairing-day-grid__actions'>{
                index === 0 ?
                    <><MoveUpButton hide='true'/>
                        <MoveDownButton role='claimant' id={pairing.claimant.id}/></>
                    : index === groupSize/2 -1 ? <MoveUpButton role='claimant' id={pairing.claimant.id}/>
                        :<><MoveUpButton role='claimant' id={pairing.claimant.id}/> <MoveDownButton role='claimant' id={pairing.claimant.id} /></>
            }</div>
            <div style={{textAlign: 'right'}}>{pairing.claimantGroupPosition}</div>
            <div style={{paddingLeft: '0.5rem'}}>{pairing.claimant.school}</div>
            <div>{pairing.claimant.jurisdiction}</div>
            <div className='draft-pairing-day-grid__actions'>{
                index === groupSize / 2 -1 ?
                    <MoveUpButton role='respondent' id={pairing.respondent.id} />
                    : index === 0  ? <><MoveUpButton hide='true'/><MoveDownButton role='respondent' id={pairing.respondent.id} /></>
                        : <><MoveUpButton role='respondent' id={pairing.respondent.id}/> <MoveDownButton role='respondent' id={pairing.respondent.id} /></>
            }</div>
            <div style={{textAlign: 'right'}}>{pairing.respondentGroupPosition}</div>
            <div style={{paddingLeft: '0.5rem'}}>{pairing.respondent.school}</div>
            <div>{pairing.respondent.jurisdiction}</div>
        </div>
    )
}

export default PairingDayRecord