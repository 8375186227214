import * as Actions from './actionTypes/adminActionTypes'
import * as api from '../api'
import makeAction from "./actionCreator";
import {TEAM_ROLE_TYPE} from "../utils/constants";
import {get, isEmpty, head, filter} from "lodash/fp";
import {display} from "@mui/system";

export const getAllArbitrators = () => async (dispatch,getState) => {
    dispatch(makeAction(Actions.GET_ALL_ARBITRATORS_REQUESTED))

    try {
        const result = await api.getArbitrators()
        dispatch(makeAction(Actions.GET_ALL_ARBITRATORS_SUCCEEDED, result))
        return result
    } catch (e) {
        dispatch(makeAction(Actions.GET_ALL_ARBITRATORS_FAILED, {},e))
    }
}

export const getAllTeams = () => async (dispatch,getState) => {
    dispatch(makeAction(Actions.GET_ALL_TEAMS_REQUESTED))

    try {
        const result = await api.getTeams()
        dispatch(makeAction(Actions.GET_ALL_TEAMS_SUCCEEDED, result))
        return result
    } catch (e) {
        dispatch(makeAction(Actions.GET_ALL_TEAMS_FAILED, {},e))
    }
}


export const setTeamStatus = (id,status,notify) => async (dispatch,getState) => {
    dispatch(makeAction(Actions.SET_TEAM_STATUS_REQUESTED))

    try {
        const result = await api.setTeamStatus(id,status,notify)
        dispatch(makeAction(Actions.SET_TEAM_STATUS_SUCCEEDED, result))
    } catch (e) {
        dispatch(makeAction(Actions.SET_TEAM_STATUS_FAILED, {},e))
    }
}


export const setArbitratorStatus = (id,status,notify) => async (dispatch,getState) => {
    dispatch(makeAction(Actions.SET_ARBITRATOR_STATUS_REQUESTED))

    try {
        const result = await api.setArbitratorStatus(id,status,notify)
        dispatch(makeAction(Actions.SET_ARBITRATOR_STATUS_SUCCEEDED, result))
        //Refresh the arbitrators from the server
        await (getAllArbitrators())(dispatch,getState)
    } catch (e) {
        dispatch(makeAction(Actions.SET_ARBITRATOR_STATUS_FAILED, {},e))
    }
}

export const getAllClarifications = () => async (dispatch,getState) => {
    dispatch(makeAction(Actions.GET_ALL_CLARIFICATIONS_REQUESTED))

    try {
        const result = await api.getClarifications()
        dispatch(makeAction(Actions.GET_ALL_CLARIFICATIONS_SUCCEEDED, result))
    } catch (e) {
        dispatch(makeAction(Actions.GET_ALL_CLARIFICATIONS_FAILED, {},e))
    }
}


export const exportArbitratorMissingRankings = (role, isJury=false) => async (dispatch,getState) => {
    dispatch(makeAction(Actions.EXPORT_ARBITRATOR_MISSING_RANKINGS_REQUESTED))

    try {
        const result = await api.exportArbitratorMissingRankings(role,isJury)
        dispatch(makeAction(Actions.EXPORT_ARBITRATOR_MISSING_RANKINGS_SUCCEEDED, result))
        return result
    } catch (e) {
        dispatch(makeAction(Actions.EXPORT_ARBITRATOR_MISSING_RANKINGS_FAILED, {},e))
    }
}

export const exportAllTeamMembers = () => async (dispatch,getState) => {
    dispatch(makeAction(Actions.EXPORT_ALL_TEAM_MEMBERS_REQUESTED))

    try {
        const result = await api.exportAllTeamMembers()
        dispatch(makeAction(Actions.EXPORT_ALL_TEAM_MEMBERS_SUCCEEDED, result))
        return result
    } catch (e) {
        dispatch(makeAction(Actions.EXPORT_ALL_TEAM_MEMBERS_FAILED, {},e))
    }
}

export const exportAllTeamPaymentRecords = () => async (dispatch,getState) => {
    dispatch(makeAction(Actions.EXPORT_ALL_TEAM_PAYMENT_RECORDS_REQUESTED))

    try {
        const result = await api.exportAllTeamPaymentRecords()
        dispatch(makeAction(Actions.EXPORT_ALL_TEAM_PAYMENT_RECORDS_SUCCEEDED, result))
        return result
    } catch (e) {
        dispatch(makeAction(Actions.EXPORT_ALL_TEAM_PAYMENT_RECORDS_FAILED, {},e))
    }
}


export const downloadTeamDocument = (id) => async (dispatch,getState) => {
    dispatch(makeAction(Actions.DOWNLOAD_TEAM_DOCUMENT_REQUESTED))

    try {
        const result = await api.downloadTeamDocument(id)
        dispatch(makeAction(Actions.DOWNLOAD_TEAM_DOCUMENT_SUCCEEDED, result))
        return result
    } catch (e) {
        dispatch(makeAction(Actions.DOWNLOAD_TEAM_DOCUMENT_FAILED, {},e))
    }
}


export const getAllMemoRankings = (role) => async (dispatch,getState) => {

    if (role === TEAM_ROLE_TYPE.CLAIMANT){
        await getAllClaimantRankings(role)(dispatch,getState)
    }else if (role === TEAM_ROLE_TYPE.RESPONDENT){
        await getAllRespondentRankings(role)(dispatch,getState)
    }
}

export const getAllMemoAssignments = (role) => async (dispatch,getState) => {

    let result = []
    if (role === TEAM_ROLE_TYPE.CLAIMANT){
        result = await getAllClaimantAssignments(role)(dispatch,getState)
    }else if (role === TEAM_ROLE_TYPE.RESPONDENT){
        result = await getAllRespondentAssignments(role)(dispatch,getState)
    }
    return result
}

const getAllClaimantRankings = role => async (dispatch,getState) => {
    dispatch(makeAction(Actions.GET_ALL_CLAIMANT_MEMO_RANKINGS_REQUESTED,role))
    try {
        const result = await api.getMemoRankings(role)
        dispatch(makeAction(Actions.GET_ALL_CLAIMANT_MEMO_RANKINGS_SUCCEEDED, result))
    } catch (e) {
        dispatch(makeAction(Actions.GET_ALL_CLAIMANT_MEMO_RANKINGS_FAILED, {},e))
    }
}

const getAllRespondentRankings = role => async (dispatch,getState) => {
    dispatch(makeAction(Actions.GET_ALL_RESPONDENT_MEMO_RANKINGS_REQUESTED,role))
    try {
        const result = await api.getMemoRankings(role)
        dispatch(makeAction(Actions.GET_ALL_RESPONDENT_MEMO_RANKINGS_SUCCEEDED, result))
        return result
    } catch (e) {
        dispatch(makeAction(Actions.GET_ALL_RESPONDENT_MEMO_RANKINGS_FAILED, {},e))
    }
}

const getAllClaimantAssignments = role => async (dispatch,getState) => {
    dispatch(makeAction(Actions.GET_ALL_CLAIMANT_MEMO_ASSIGNMENTS_REQUESTED,role))
    try {
        const result = await api.getMemoAssignments(role)
        dispatch(makeAction(Actions.GET_ALL_CLAIMANT_MEMO_ASSIGNMENTS_SUCCEEDED, result))
        return result
    } catch (e) {
        dispatch(makeAction(Actions.GET_ALL_CLAIMANT_MEMO_ASSIGNMENTS_FAILED, {},e))
    }
}

const getAllRespondentAssignments = role => async (dispatch,getState) => {
    dispatch(makeAction(Actions.GET_ALL_RESPONDENT_MEMO_ASSIGNMENTS_REQUESTED,role))
    try {
        const result = await api.getMemoAssignments(role)
        dispatch(makeAction(Actions.GET_ALL_RESPONDENT_MEMO_ASSIGNMENTS_SUCCEEDED, result))
        return result
    } catch (e) {
        dispatch(makeAction(Actions.GET_ALL_RESPONDENT_MEMO_ASSIGNMENTS_FAILED, {},e))
    }
}

export const exportAllMemoRankings = role => async (dispatch,getState) => {
    dispatch(makeAction(Actions.EXPORT_ALL_MEMO_RANKINGS_REQUESTED,role))

    try {
        const result = await api.exportMemoRankings(role)
        dispatch(makeAction(Actions.EXPORT_ALL_MEMO_RANKINGS_SUCCEEDED, result))
        return result
    } catch (e) {
        dispatch(makeAction(Actions.EXPORT_ALL_MEMO_RANKINGS_FAILED, {},e))
    }
}


//
export const getAllJuryMemoRankings = (role) => async (dispatch,getState) => {

    if (role === TEAM_ROLE_TYPE.CLAIMANT){
        await getAllJuryClaimantRankings(role)(dispatch,getState)
    }else if (role === TEAM_ROLE_TYPE.RESPONDENT){
        await getAllJuryRespondentRankings(role)(dispatch,getState)
    }
}

const getAllJuryClaimantRankings = role => async (dispatch,getState) => {
    dispatch(makeAction(Actions.GET_ALL_JURY_CLAIMANT_MEMO_RANKINGS_REQUESTED,role))
    try {
        const result = await api.getJuryMemoRankings(role)
        dispatch(makeAction(Actions.GET_ALL_JURY_CLAIMANT_MEMO_RANKINGS_SUCCEEDED, result))
    } catch (e) {
        dispatch(makeAction(Actions.GET_ALL_JURY_CLAIMANT_MEMO_RANKINGS_FAILED, {},e))
    }
}

const getAllJuryRespondentRankings = role => async (dispatch,getState) => {
    dispatch(makeAction(Actions.GET_ALL_JURY_RESPONDENT_MEMO_RANKINGS_REQUESTED,role))
    try {
        const result = await api.getJuryMemoRankings(role)
        dispatch(makeAction(Actions.GET_ALL_JURY_RESPONDENT_MEMO_RANKINGS_SUCCEEDED, result))
    } catch (e) {
        dispatch(makeAction(Actions.GET_ALL_JURY_RESPONDENT_MEMO_RANKINGS_FAILED, {},e))
    }
}


export const getAllJuryMemoAssignments = (role) => async (dispatch,getState) => {

    if (role === TEAM_ROLE_TYPE.CLAIMANT){
        await getAllJuryClaimantAssignments(role)(dispatch,getState)
    }else if (role === TEAM_ROLE_TYPE.RESPONDENT){
        await getAllJuryRespondentAssignments(role)(dispatch,getState)
    }
}

const getAllJuryClaimantAssignments = role => async (dispatch,getState) => {
    dispatch(makeAction(Actions.GET_ALL_JURY_CLAIMANT_MEMO_ASSIGNMENTS_REQUESTED,role))
    try {
        const result = await api.getJuryMemoAssignments(role)
        dispatch(makeAction(Actions.GET_ALL_JURY_CLAIMANT_MEMO_ASSIGNMENTS_SUCCEEDED, result))
    } catch (e) {
        dispatch(makeAction(Actions.GET_ALL_JURY_CLAIMANT_MEMO_ASSIGNMENTS_FAILED, {},e))
    }
}

const getAllJuryRespondentAssignments = role => async (dispatch,getState) => {
    dispatch(makeAction(Actions.GET_ALL_JURY_RESPONDENT_MEMO_ASSIGNMENTS_REQUESTED,role))
    try {
        const result = await api.getJuryMemoAssignments(role)
        dispatch(makeAction(Actions.GET_ALL_JURY_RESPONDENT_MEMO_ASSIGNMENTS_SUCCEEDED, result))
    } catch (e) {
        dispatch(makeAction(Actions.GET_ALL_JURY_RESPONDENT_MEMO_ASSIGNMENTS_FAILED, {},e))
    }
}



export const setTeamJuryStatus = (id,status, role) => async (dispatch,getState) => {
    dispatch(makeAction(Actions.SET_TEAM_JURY_STATUS_REQUESTED))

    try {
        const result = await api.setTeamJuryStatus(id,status,role)
        dispatch(makeAction(Actions.SET_TEAM_JURY_STATUS_SUCCEEDED, result))
        //Refresh the rankings from the server
        await (getAllMemoRankings(role))(dispatch,getState)
    } catch (e) {
        dispatch(makeAction(Actions.SET_TEAM_JURY_STATUS_FAILED, {},e))
    }
}


export const exportAllArbitratorConflicts = () => async (dispatch,getState) =>{
    dispatch(makeAction(Actions.EXPORT_ALL_ARBITRATOR_CONFLICTS_REQUESTED))

    try {
        const result = await api.exportAllArbitratorConflicts()
        dispatch(makeAction(Actions.EXPORT_ALL_ARBITRATOR_CONFLICTS_SUCCEEDED, result))
        return result
    } catch (e) {
        dispatch(makeAction(Actions.EXPORT_ALL_ARBITRATOR_CONFLICTS_FAILED, {},e))
    }
}

export const runPairing = () => async (dispatch,getState) => {
    dispatch(makeAction(Actions.RUN_PAIRING_REQUESTED))

    try {
        const result = await api.runPairing()
        dispatch(makeAction(Actions.RUN_PAIRING_SUCCEEDED, result))
        return result
    } catch (e) {
        dispatch(makeAction(Actions.RUN_PAIRING_FAILED, {},e))
    }

}

export const getOralArgumentSchedule = () => async (dispatch,getState) => {
    let roundType = get('admin.selectedRoundType',getState())
    if (!roundType)
    {
        roundType = 'General Rounds'
    }

    dispatch(makeAction(Actions.GET_ORAL_ARGUMENT_SCHEDULE_REQUESTED))
    try {
        const result = await api.getOralArgumentSchedule(roundType)
        dispatch(makeAction(Actions.GET_ORAL_ARGUMENT_SCHEDULE_SUCCEEDED, result))
        return result
    } catch (e) {
        dispatch(makeAction(Actions.GET_ORAL_ARGUMENT_SCHEDULE_FAILED, {},e))
    }
}

export const getOralArgumentScheduleForPairing = id => async (dispatch,getState) => {
    dispatch(makeAction(Actions.GET_ORAL_ARGUMENT_SCHEDULE_REQUESTED))
    try {
        const result = await api.getOralArgumentScheduleForPairing(id)
        dispatch(makeAction(Actions.GET_ORAL_ARGUMENT_SCHEDULE_SUCCEEDED, result))
        return result
    } catch (e) {
        dispatch(makeAction(Actions.GET_ORAL_ARGUMENT_SCHEDULE_REQUESTED, {},e))
    }
}

export const selectPairing = id => async (dispatch,getState) => {
    let roundSchedule = get('admin.oralArgumentSchedule',getState())
    if (isEmpty(roundSchedule)){
        roundSchedule = await getOralArgumentScheduleForPairing(id)(dispatch,getState)
        //roundSchedule = get('admin.oralArgumentSchedule',getState())
    }
    const pairing = head(filter(fs => fs.id === Number.parseInt(id),roundSchedule))
    dispatch(makeAction(Actions.SELECT_PAIRING,pairing))
    let roundType = get('admin.selectedRoundType',getState())
    if (pairing.roundType !== roundType) {
        await selectRound(pairing.roundType,getState())(dispatch, getState)
    }

    return pairing
}

export const savePairing = (pairing) => async (dispatch,getState) => {
    dispatch(makeAction(Actions.SAVE_PAIRING_REQUESTED))

    try {
        const result = await api.savePairing(pairing)
        dispatch(makeAction(Actions.SAVE_PAIRING_SUCCEEDED, result))
        await getOralArgumentSchedule()(dispatch,getState)
        await selectPairing(result.id)(dispatch,getState)
        return result
    } catch (e) {
        dispatch(makeAction(Actions.SAVE_PAIRING_FAILED, {},e))
    }
}

export const saveScores = (pairingId,scores) => async (dispatch,getState) => {
    dispatch(makeAction(Actions.SAVE_SCORES_REQUESTED))

    try {
        const result = await api.saveScores(pairingId,scores)
        dispatch(makeAction(Actions.SAVE_SCORES_SUCCEEDED, result))
        await getOralArgumentSchedule()(dispatch,getState)
        await selectPairing(result.id)(dispatch,getState)
        return result
    } catch (e) {
        dispatch(makeAction(Actions.SAVE_SCORES_FAILED, {},e))
    }
}


export const selectRound = roundType => async (dispatch,getState) => {
    let rounds = get('admin.rounds',getState())
    if (isEmpty(rounds)){
        rounds = await api.getRounds()
    }

    const currentRoundType = get('admin.selectedRoundType',getState())
    if (currentRoundType !== roundType) {
        dispatch(makeAction(Actions.SELECT_ROUND,head(filter(r => r.roundType === roundType,rounds))))
        await getOralArgumentSchedule(roundType)(dispatch, getState)
    }
    return rounds
}


export const getRounds = () => async (dispatch,getState) => {
    dispatch(makeAction(Actions.GET_ROUNDS_REQUESTED))
    try {
        const result = await api.getRounds()
        // let selectedRound = get('admin.selectedRound',getState())
        // if (!selectedRound.roundType) {
        //     await selectRound(head(result).roundType)(dispatch, getState)
        // }

        dispatch(makeAction(Actions.GET_ROUNDS_SUCCEEDED, result))
        return result
    } catch (e) {
        dispatch(makeAction(Actions.GET_ROUNDS_FAILED), {}, e)
    }
}


export const saveRoundWinners = (roundWinners) => async (dispatch,getState) => {
    dispatch(makeAction(Actions.SAVE_ROUND_WINNERS_REQUESTED, { roundWinners}))
    try{
        const result = await api.saveRoundWinners(roundWinners)
        dispatch(makeAction(Actions.SAVE_ROUND_WINNERS_SUCCEEDED,result))
        await getOralArgumentSchedule()(dispatch,getState)
        return result
    }catch (e)
    {
        dispatch(makeAction(Actions.SAVE_ROUND_WINNERS_FAILED),{roundWinners},e)
    }
}

export const pairRound = (roundType,scores) => async (dispatch,getState) => {
    dispatch(makeAction(Actions.PAIR_ROUND_REQUESTED, { roundType }))
    try{
        const result = await api.pairRound(roundType, scores)
        await selectRound(roundType)(dispatch,getState)
        dispatch(makeAction(Actions.PAIR_ROUND_SUCCEEDED,result))
        return result
    }catch (e) {
        dispatch(makeAction(Actions.PAIR_ROUND_FAILED),{roundType},e)
    }
}

export const pairWinnerRound = (roundType) => async (dispatch,getState) => {
    dispatch(makeAction(Actions.PAIR_WINNER_ROUND_REQUESTED, { roundType }))
    try{
        const result = await api.pairWinnerRound(roundType)
        await selectRound(roundType)(dispatch,getState)
        dispatch(makeAction(Actions.PAIR_WINNER_ROUND_SUCCEEDED,result))
        return result
    }catch (e) {
        dispatch(makeAction(Actions.PAIR_WINNER_ROUND_FAILED),{roundType},e)
    }
}

export const publishRound = roundType => async(dispatch,getState) => {
    dispatch(makeAction(Actions.PUBLISH_ROUND_REQUESTED, { roundType }))
    try{
        const result = await api.publishRound(roundType)
        await selectRound(roundType)(dispatch,getState)
        dispatch(makeAction(Actions.PUBLISH_ROUND_SUCCEEDED,result))
        await getOralArgumentSchedule()(dispatch,getState)
        return result
    }catch (e) {
        dispatch(makeAction(Actions.PUBLISH_ROUND_FAILED),{roundType},e)
    }
}

export const publishArbitratorPairings = (role) => async (dispatch, getState)=> {
    dispatch(makeAction(Actions.PUBLISH_ARBITRATOR_PAIRINGS_REQUESTED))
    try{
        const result = await api.publishArbitratorPairings(role)
        dispatch(makeAction(Actions.PUBLISH_ARBITRATOR_PAIRINGS_SUCCEEDED,result))
        return result
    }catch (e){
        dispatch(makeAction(Actions.PUBLISH_ARBITRATOR_PAIRINGS_FAILED,e))
    }
}

export const updateArbitratorPairing = updatedPairing => async (dispatch, getState)=> {
    dispatch(makeAction(Actions.UPDATE_ARBITRATOR_PAIRING_REQUESTED, updatedPairing))
    try{
        const result = await api.updateArbitratorPairing(updatedPairing)
        dispatch(makeAction(Actions.UPDATE_ARBITRATOR_PAIRING_SUCCEEDED,result))
        return result
    }catch (e){
        dispatch(makeAction(Actions.UPDATE_ARBITRATOR_PAIRING_FAILED,updatedPairing,e))
    }
}

export const saveArbitratorPairing = newPairing => async (dispatch, getState)=> {
    dispatch(makeAction(Actions.SAVE_ARBITRATOR_PAIRING_REQUESTED,newPairing))
    try{
        const result = await api.saveArbitratorPairing(newPairing)
        dispatch(makeAction(Actions.SAVE_ARBITRATOR_PAIRING_SUCCEEDED,result))
        return result
    }catch (e){
        dispatch(makeAction(Actions.SAVE_ARBITRATOR_PAIRING_FAILED,newPairing,e))
    }
}

export const deleteArbitratorPairing = payload => async (dispatch, getState)=> {
    dispatch(makeAction(Actions.DELETE_ARBITRATOR_PAIRING_REQUESTED,payload))
    try{
        const result = await api.deleteArbitratorPairing(payload)
        dispatch(makeAction(Actions.DELETE_ARBITRATOR_PAIRING_SUCCEEDED,result))
        return result
    }catch (e){
        dispatch(makeAction(Actions.DELETE_ARBITRATOR_PAIRING_FAILED,payload,e))
    }
}

export const getMemoAssignableTeams = () => async (dispatch, getState)=> {
    dispatch(makeAction(Actions.GET_MEMO_ASSIGNABLE_TEAMS_REQUESTED))
    try{
        const result = await api.getMemoAssignableTeams()
        dispatch(makeAction(Actions.GET_MEMO_ASSIGNABLE_TEAMS_SUCCEEDED,result))
        return result
    }catch (e){
        dispatch(makeAction(Actions.GET_MEMO_ASSIGNABLE_TEAMS_FAILED,e))
    }
}

export const generateWrittenArbitratorPairings = role => async (dispatch, getState)=> {
    dispatch(makeAction(Actions.GENERATE_WRITTEN_ARBITRATOR_PAIRINGS_REQUESTED,role))
    try{
        const result = await api.generateWrittenArbitratorPairings(role)
        dispatch(makeAction(Actions.GENERATE_WRITTEN_ARBITRATOR_PAIRINGS_SUCCEEDED,result))
        return result
    }catch (e){
        dispatch(makeAction(Actions.GENERATE_WRITTEN_ARBITRATOR_PAIRINGS_FAILED,e))
    }
}

export const exportArbitratorWrittenPairings = role => async (dispatch,getState) => {
    dispatch(makeAction(Actions.EXPORT_WRITTEN_ARBITRATOR_PAIRINGS_REQUESTED,role))
    try{
        const result = await api.exportWrittenArbitratorPairings(role)
        dispatch(makeAction(Actions.EXPORT_WRITTEN_ARBITRATOR_PAIRINGS_SUCCEEDED,result))
        return result
    }catch (e){
        dispatch(makeAction(Actions.EXPORT_WRITTEN_ARBITRATOR_PAIRINGS_FAILED,e))
    }
}

export const addArbitratorToJuryRanking = (arbitratorId,role) => async (dispatch,getState) => {
    dispatch(makeAction(Actions.ADD_ARBITRATOR_TO_JURY_RANKING_REQUESTED,{arbitratorId, role}))
    try{
        const result = await api.addArbitratorToJuryRanking(arbitratorId,role)
        dispatch(makeAction(Actions.ADD_ARBITRATOR_TO_JURY_RANKING_SUCCEEDED,result))
        return result
    }catch (e){
        dispatch(makeAction(Actions.ADD_ARBITRATOR_TO_JURY_RANKING_FAILED,e))
    }
}

export const removeArbitratorFromJuryRanking = (arbitratorId,role) => async (dispatch,getState) => {
    dispatch(makeAction(Actions.REMOVE_ARBITRATOR_FROM_JURY_RANKING_REQUESTED,{ arbitratorId, role}))
    try{
        const result = await api.removeArbitratorFromJuryRanking(arbitratorId,role)
        dispatch(makeAction(Actions.REMOVE_ARBITRATOR_FROM_JURY_RANKING_SUCCEEDED,result))
        return result
    }catch (e){
        dispatch(makeAction(Actions.REMOVE_ARBITRATOR_FROM_JURY_RANKING_FAILED,e))
    }
}

export const publishArbitratorJurySelections = role => async (dispatch,getState) => {
    dispatch(makeAction(Actions.PUBLISH_ARBITRATOR_JURY_SELECTIONS_REQUESTED,{role}))
    try{
        const result = await api.publishArbitratorJurySelections(role)
        dispatch(makeAction(Actions.PUBLISH_ARBITRATOR_JURY_SELECTIONS_SUCCEEDED,result))
        return result
    }catch (e){
        dispatch(makeAction(Actions.PUBLISH_ARBITRATOR_JURY_SELECTIONS_FAILED,e))
    }
}

export const getDraftPairings = () => async (dispatch, getState) => {
    dispatch(makeAction(Actions.GET_DRAFT_PAIRINGS_REQUESTED))
    try{
        const result = await api.getDraftPairings()
        dispatch(makeAction(Actions.GET_DRAFT_PAIRINGS_SUCCEEDED,result))
        return result
    }catch (e){
        dispatch(makeAction(Actions.GET_DRAFT_PAIRINGS_FAILED,e))
    }
}

export const generateDraftPairings = () => async (dispatch, getState) => {
    dispatch(makeAction(Actions.GENERATE_DRAFT_PAIRINGS_REQUESTED))
    try {
        const result = await api.generateDraftPairings()
        dispatch(makeAction(Actions.GENERATE_DRAFT_PAIRINGS_SUCCEEDED,result))
        return result
    }catch (e) {
        dispatch(makeAction(Actions.GENERATE_DRAFT_PAIRINGS_FAILED,e))
    }
}

export const saveDraftPairings = (draftPairings) => async (dispatch, getState) => {
    dispatch(makeAction(Actions.SAVE_DRAFT_PAIRINGS_REQUESTED))
    try {
        const result = await api.saveDraftPairings(draftPairings,true)
        dispatch(makeAction(Actions.SAVE_DRAFT_PAIRINGS_SUCCEEDED,result))
        return result
    }catch (e) {
        dispatch(makeAction(Actions.SAVE_DRAFT_PAIRINGS_FAILED,e))
    }
}

export const saveRealPairings = (draftPairings) => async (dispatch, getState) => {
    dispatch(makeAction(Actions.SAVE_REAL_PAIRINGS_REQUESTED))
    try {
        const result = await api.saveDraftPairings(draftPairings,false)
        dispatch(makeAction(Actions.SAVE_REAL_PAIRINGS_SUCCEEDED,result))
        return result
    }catch (e) {
        dispatch(makeAction(Actions.SAVE_REAL_PAIRINGS_FAILED,e))
    }
}