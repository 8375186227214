import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import React, {useEffect, useState} from 'react'
import {noop, get, head, filter, toLower, startCase, map, includes} from "lodash/fp";
import { connect } from 'react-redux'
import * as actions from '../../../actions/teamActions'
import PropTypes from "prop-types";
import {Box, Button, CircularProgress, Snackbar} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { ACCEPTED, HOLD } from '../../../consts/status.js'
import Unauthorized from "../../Unauthorized";
import {getSelectedTeam } from '../../../reducers/teamSelectors'
import {getIsDocumentSubmissionOpen} from "../../../reducers/mootSelectors";
import {format, parseISO} from "date-fns";
import {utcToZonedTime} from "date-fns-tz";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {toBase64} from "./uploadUtils";
import { TEAM_ROLE_TYPE } from "../../../utils/constants";
import Select from "../../common/Select";
import {Alert} from "@mui/material";
import {TEAM_OWNER} from "../../../consts/roles";

const UploadDocument = ({ router, team, type,user, ordinalMootNumber, uploadDocument = noop,uploadDocumentError, isOpen= false, isAvailableCritique = false, getSelectedTeam = noop}) => {

    const [isSaving, setIsSaving] = useState(false);
    const [saved, setSaved] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const [isConsentChecked,setIsConsentChecked] = useState(false)
    const [isBothMootsChecked,setIsBothMootsChecked] = useState(false)
    const [selectedMoot, setSelectedMoot] = useState()

    const [document, setDocument] = useState()
    const fileRef = React.useRef();

    const override = router.location.query.override


    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSaved(false);
    };

    const onSave = async (e, type, file) => {
        try {
            e.preventDefault()
            setIsSaving(true)
            const base64File = await toBase64(file)
            const whichMoot = isBothMootsChecked && (selectedMoot || '')
            const didSave = await uploadDocument(type, whichMoot, base64File.body, override)
            if (didSave) {
                setSaved(true)
            }
        } finally {
            setIsSaving(false)
            setSelectedMoot()
            setDocument()
            setIsBothMootsChecked(false)
            setIsConsentChecked(false)
            fileRef.current.value = null;
        }
    }

    useEffect(() => {
        async function getData() {
            await getSelectedTeam()
            await getIsDocumentSubmissionOpen()
            setIsLoading(false)
        }

        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])


    const isAllowed = get('status', team) === ACCEPTED || get('status', team) === HOLD
    const shouldShowForm = includes(TEAM_OWNER,user.roles)

    const existingDocument = head(filter(d => d.documentType === type, team.TeamDocuments))
    const criticalComments = filter(d => d.documentType === `CRITICAL_COMMENTS_${type}`, team.TeamDocuments)

    return (
        <Container style={{ padding: '0', marginTop: '2rem'}}>
            {isLoading &&
            <CircularProgress/>
            }
            {!isLoading && !isAllowed &&
            <Unauthorized/>
            }
            {!isLoading && isAllowed &&
                <Container style={{ padding: '0' }}>
                    
                {existingDocument &&
                <Grid container style={{ flexGrow: 1, margin: '1rem 0 2rem', maxWidth: '650px' }} spacing={0}>
                    <Grid item xs={6} md={6}>                    
                        <div style={{ fontWeight: 'bold', margin: '0 0 0.5rem 0'}}>File </div><a target="_blank" rel="noreferrer" style={{ margin: '0 1rem 0 0'}} href={get('documentUrl', existingDocument)}>{team.id}-{toLower(type)}.pdf</a>                               
                    </Grid>
                    <Grid item xs={6} md={6}>
                        <div style={{ fontWeight: 'bold', margin: '0 0 0.5rem 0'}}>Last updated HKT </div><span>{format(utcToZonedTime(existingDocument.updatedAt,'Asia/Hong_Kong'), 'yyyy-MM-dd HH:mm')}</span>            
                    </Grid>
                </Grid>
                }
  
                {criticalComments.length > 0 && isAvailableCritique &&
                <>
                    <Grid item xs={12} md={12}>
                            <div style={{ fontWeight: 'bold', margin: '0 0 0.5rem 0'}}>Memorandum Critique Files </div>
                    </Grid>
                    {/*
                    <Grid item xs={6} md={6}>
                        <div style={{ fontWeight: 'bold', margin: '0 0 0.5rem 0'}}>Submitted </div><span>{format(parseISO(c.updatedAt),'yyyy-MM-dd hh:mm')}</span>
                    </Grid>
                    */}
                </>
                }
                
                {isAvailableCritique && map((c, index) => (<Grid container key={"item-" + c.id} style={{ flexGrow: 1, margin: '1rem 0', maxWidth: '650px' }} spacing={0}>                   
                    <Grid item xs={6} md={6}>
                        <a target="_blank" rel="noreferrer" style={{ margin: '0 1rem 0 0'}} href={get('documentUrl', c)}>{toLower(c.name)}</a>
                    </Grid>                    
                </Grid>),criticalComments)
            }          
            </Container>
            }
            {!isLoading && isAllowed &&
            <Grid item xs={12} md={12}>
                {isOpen && shouldShowForm &&
                <form onSubmit={e => onSave(e, type, document)}>                   
                    <Typography style={{ margin: "1rem 0"}}>To {existingDocument ? 'replace' : 'upload'} your {startCase(toLower(type))}'s Memorandum, click on "Choose File" below.</Typography>                                     
                    <Box style={{ margin: "1rem 0"}}>
                        <FormControlLabel
                            style={{display:'table'}}
                            control={
                                <div style={{display:'table-cell'}}>
                                    <Checkbox
                                        checked={isConsentChecked}
                                        onChange={() => setIsConsentChecked(!isConsentChecked)}
                                        color='primary'
                                        required                                        
                                    />
                                </div>
                            }
                            label={`By ticking this box, we, ${team.school}, certify that no other person other than a student team member has participated in the writing of the ${startCase(toLower(type))}'s Memorandum.`}                            
                        />
                    </Box>
                    {type === TEAM_ROLE_TYPE.CLAIMANT &&
                    <Box>
                        <FormControlLabel
                            style={{display:'table'}}
                            control={
                                <div style={{display:'table-cell'}}>
                                    <Checkbox
                                        checked={isBothMootsChecked}
                                        onChange={() => setIsBothMootsChecked(!isBothMootsChecked)}
                                        color='primary'
                                    />
                                </div>
                            }
                            label={`By ticking this box, we, ${team.school}, confirm that we have submitted the same ${startCase(toLower(type))}'s Memorandum for both the Vis Moots in Vienna and in Hong Kong.`}
                        />
                    </Box>
                    }
                    { isBothMootsChecked &&
                        <Grid style={{ paddingLeft: "30px"}}>
                            <Box style={{ margin: "1rem 0 0"}}>
                                <label>{team.school} further confirms that it wishes for the selected moot to consider its Claimant's Memorandum for an award.</label>
                            </Box>
                            <Box style={{ display: 'flex', margin: '5px 0', maxWidth: '225px' }}>
                                <Select
                                    style={{ marginRight: '10px' }}
                                    id={`whichMoot`}
                                    onChange={(e) => setSelectedMoot(e.target.value)}
                                    value={selectedMoot}
                                    options={['Vis Vienna','Vis East']}
                                    label="Which moot?"
                                    required
                                />
                            </Box>
                        </Grid>

                    }
                    <Box style={{ margin: "2rem 0 2.5rem"}}>
                        <input type="file" ref={fileRef}  accept="application/pdf" onChange={e => setDocument(e.target.files[0])}/>
                    </Box>
                    <Box style={{ margin: "1rem 0", display: "flex", gap: "10px"}}>
                        <Button variant="contained" type="submit" color="primary" disabled={!isConsentChecked || !document || isSaving}>Upload</Button>
                        {isSaving &&
                        <CircularProgress size={35}/>
                        }
                    </Box>
                </form>
                }                
                {uploadDocumentError &&
                <Typography color='error'>{uploadDocumentError}</Typography>
                }
                <Snackbar open={saved} onClose={handleClose} autoHideDuration={6000} >
                    <Alert onClose={handleClose}  severity="success" sx={{ width: '100%' }}>
                    Thank you for uploading your {startCase(toLower(type))}'s Memorandum. The Vis East Administration confirms safe receipt of your upload.
                    </Alert>
                </Snackbar>
            </Grid>
            }
        </Container>
    )
}


UploadDocument.propTypes = {
    getSelectedTeam: PropTypes.func,
    saveDocuments: PropTypes.func,
    team: PropTypes.object
}

export default connect(
(state, ownProps) => ({
    router: state.router,
    team: state.team.selectedTeam,
    type: ownProps.type,
    isOpen: ownProps.isOpen,
    uploadDocumentError: state.team.uploadDocumentError,
    user: state.user,
}),
    {
        getSelectedTeam: getSelectedTeam,
        uploadDocument: actions.uploadDocument,
    }
)(UploadDocument)