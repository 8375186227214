import PropTypes from "prop-types";
import {connect} from "react-redux";
import Typography from "@material-ui/core/Typography";
import React, {useEffect, useState} from "react";
import {noop} from "lodash/fp";
import { saveInvitedTeamMember } from "../../actions/teamActions";
import {getTeamById} from "../../reducers/teamSelectors"
import {getCurrentMoot} from "../../reducers/mootSelectors";
import ordinal from "ordinal-number-suffix";
import {Alert, Container} from "@mui/material";
import {differenceInDays} from "date-fns";
import {Button, CircularProgress, Snackbar, TextField} from "@material-ui/core";
import TextInput from "../common/TextInput";
import Grid from "@mui/material/Grid";
import {isPasswordValid} from "../../utils/validation";
import {createUserCognitoAccount, login} from "../../utils/cognitoHelpers";
import FormHelperText from "@mui/material/FormHelperText";
import {Link, useHistory} from "react-router-dom";
import {getUser} from "../../actions/userActions";

const TeamMemberInvite = ({user, inviteCode, currentMoot, selectedTeam, getTeamById = noop,getCurrentMoot = noop,saveInvitedTeamMember = noop, getUser = noop}) => {


    const [email,setEmail] = useState("");
    const [loading,setLoading] = useState(true)
    const [pending,setPending] = useState(false)
    const [inviteCodeValid,setInviteCodeValid] = useState(true)
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const passwordsMatch = password === confirmPassword
    const isInvalidPassword = password && !isPasswordValid(password)
    const history = useHistory()
    const [userError,setUserError] = useState(false)


    const validateInvite = async () => {
        if (inviteCode) {
            const decoded = window.atob(inviteCode)
            const parts = decoded.split('-')
            const teamId = parts[0]
            const email = parts[1].replaceAll('##||##','-')
            const ticks = Number(parts[2])

            const now = new Date().getTime()
            const daysDiff = differenceInDays(now, ticks)
            const oneWeek = 7
            if (daysDiff > oneWeek) {
                setInviteCodeValid(false)
            }

            setEmail(email)

            return {teamId, email, ticks}
        }
    }


    useEffect(() => {
        async function getData() {
            const { teamId,email } = await validateInvite()
            await getTeamById(teamId)
            await getCurrentMoot()
            setLoading(false)

            if (user && user.username === email){
                //if there is a logged in user AND it is the invited user
                // then just process the registration
                try {
                    setPending(true)
                    await saveInvitedTeamMember(user?.idToken?.payload?.sub, inviteCode)
                    await getUser()
                    history.push(`/`)
                }finally {
                    setPending(false)
                }
            }else if (user && user.username && user.username !== email) {
                setUserError('The invited email does not match the logged in user')
            }
        }
        getData()
    },[])

    const registerTeamMember = async (e) => {
        e.preventDefault()
        try {
            setPending(true)

            const result = await createUserCognitoAccount(email, password)
            if (result) {
                await saveInvitedTeamMember(result, inviteCode)
                await login(email, password)
                history.push(`/`)
            }
            setPending(false)
        }
        catch(e) {
            setPending(false)
            if (e?.code === "UsernameExistsException") {
                history.push(`/existingAccount?teamMember=true&returnTo=team/invite/${inviteCode}`)
            }
        }
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setUserError(false)
    };


    return (
        <Container style={{paddingBottom: '1rem', paddingTop: '1rem'}}>
            {loading &&
                <CircularProgress />
            }
            {!loading && inviteCodeValid === true &&
                <>
                    <h2>You’ve been invited to join {selectedTeam?.school} team account.</h2>
                    <Typography>
                        Welcome to the {ordinal(currentMoot?.mootNumber)} Vis East Moot Competition. Please create a
                        password to start the process.
                    </Typography>

                    <form onSubmit={registerTeamMember}>
                        <Grid container
                              style={{maxWidth: '900px', paddingTop: '20px'}}
                              spacing={2}>
                            <Grid item xs={12}>
                                <TextInput disabled label='Login Email' helperText="Email will be used for logging into your account" value={email}></TextInput>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextInput
                                    name='password'
                                    type='password'
                                    onChange={e => setPassword(e.target.value)}
                                    value={password}
                                    autoComplete='new-password'
                                    label='Create Password'
                                    helperText='Min. 8 characters, one lower, one upper, one number, one special'
                                />
                                {isInvalidPassword && <Typography color='error'>Password is not strong enough</Typography>}
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextInput
                                        name='password'
                                        type='password'
                                        onChange={e => setConfirmPassword(e.target.value)}
                                        value={confirmPassword}
                                        autoComplete='new-password'
                                        label='Confirm Password'
                                    />
                                    {!passwordsMatch &&
                                        <Typography color='error' style={{marginTop: '-1rem'}}>Passwords must match</Typography>}
                                </Grid>
                            <Grid item xs={12} md={6} style={{display:'flex'}}>
                                <Button variant='contained'
                                        type='submit'
                                        color='primary'
                                        disabled={!passwordsMatch || isInvalidPassword || pending}>Register</Button>
                                {pending && <CircularProgress size={35} style={{marginLeft: '2rem'}}/>}                                
                            </Grid>
                            <Grid>
                                <FormHelperText style={{verticalAlign: "bottom"}}>Already have a login and password? <Link
                                    to={`/login?showRegister=false&returnTo=team/invite/${inviteCode}`} style={{color: '#000000'}}>Sign In</Link></FormHelperText>
                            </Grid>
                        </Grid>
                    </form>
                    <Snackbar open={userError} onClose={handleClose} autoHideDuration={6000} >
                        <Alert severity='error' sx={{ width: '100%' }}>
                            {userError}
                        </Alert>
                    </Snackbar>
                </>
            }
            {!loading && inviteCodeValid === false &&
                <Typography>The invitation is invalid</Typography>
            }
                </Container>
                )
            }

            TeamMemberInvite.propTypes = {
    inviteCode: PropTypes.string
}

export default connect(
    (state, ownProps) => ({
        inviteCode: ownProps.match.params.code,
        currentMoot: state.moot.currentMoot,
        selectedTeam: state.team.selectedTeam,
        user: state.user,
    }),{
        getTeamById: getTeamById,
        getCurrentMoot: getCurrentMoot,
        saveInvitedTeamMember: saveInvitedTeamMember,
        getUser: getUser

    })( TeamMemberInvite)