import {getCurrentUser} from "./utils/cognitoHelpers";
import {TEAM_ROLE_TYPE} from "./utils/constants";

const BASE_URL = process.env.REACT_APP_BASE_API_URL

const getCurrentUserToken = async () => {
    try {
        const currentUser = await getCurrentUser()
        return currentUser.idToken.jwtToken
    }catch{
        //if we can't get the token return null
    }
}

export async function jsonGet(endpoint,addToken = true) {
    let token
    if (addToken) {
        token = await getCurrentUserToken()
    }
    const res = await fetch(`${BASE_URL}/${endpoint}`,
        {
            headers: {
                'Authorization': token
            }
        })

    if (res.status === 400) {
        throw new Error('Bad request')
    }
    if (res.status !== 200) {
        throw Error('Server error')
    }
    try {
        return await res.json()
    } catch {
        return {}
    }
}

export async function jsonPost(endpoint, body,addToken = true) {
    let token
    if (addToken) {
        token = await getCurrentUserToken()
    }

    const res = await fetch(`${BASE_URL}/${endpoint}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        },
        body: JSON.stringify(body)
    })

    if (res.status === 400) {
        throw new Error('Bad request')
    }
    if (res.status !== 200){
        throw Error(await res.json())
    }

    try {
        return await res.json()
    } catch {
        return {}
    }
}

export async function jsonDelete(endpoint, body) {
    let token

    token = await getCurrentUserToken()

    const res = await fetch(`${BASE_URL}/${endpoint}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        },
        body: JSON.stringify(body)
    })

    if (res.status === 400) {
        throw new Error('Bad request')
    }
    if (res.status !== 200){
        throw Error('Server error')
    }

    try {
        return await res.json()
    } catch {
        return {}
    }
}

export async function jsonPatch(endpoint, body, addToken = false) {
    let token = ''
    if (addToken){
        token = await getCurrentUserToken()
    }
    const res = await fetch(`${BASE_URL}/${endpoint}`, {
        method: 'PATCH',
        headers:  {
            'Content-Type': 'application/json',
            'Authorization': token
        },
        body: JSON.stringify(body)
    })

    if (res.status === 400) {
        throw new Error('Bad request')
    }
    if (res.status !== 200){
        throw Error('Server error')
    }

    try {
        return await res.json()
    } catch {
        return {}
    }
}

export async function blobGet(endpoint,addToken = true) {
    let token
    if (addToken) {
        token = await getCurrentUserToken()
    }
    const res = await fetch(`${BASE_URL}/${endpoint}`,
        {
            headers: {
                'Authorization': token
            }
        })

    if (res.status === 400) {
        throw new Error('Bad request')
    }
    if (res.status !== 200) {
        throw Error('Server error')
    }
    try {
        return await res.blob()
    } catch {
        return {}
    }
}


export const getUserInfo = async () => jsonGet('user')

export const getTeams = async () => jsonGet('teams')
export const getTeam = async id => jsonGet(id ? `team?teamId=${id}` : `team` )
export const getSelectedTeamsPairings = async () => jsonGet('team/pairings')

export const setTeamStatus = async (id,status,notify) => jsonPatch('team/status',{id, status, notify}, true)

export const getClarifications = async () => jsonGet('clarifications', true)
export const exportArbitratorMissingRankings = async (role,isJury) => jsonGet(`admin/rankings/exportMissing?role=${role}&isJury=${isJury}`,true)
export const saveClarificationRequest = async (clarification,overrideCode) => jsonPost(`team/clarification`,{clarification,overrideCode},true)
export const getExistingClarifications = async () => jsonGet('team/clarification')

export const saveTeamMember = async (newTeamMember,override) => jsonPost(`team/member`,{newTeamMember,override},true)
export const saveInvitedTeamMember = async (cognitoId,inviteCode) => jsonPost(`team/invite`,{cognitoId,inviteCode},false)
export const deleteTeamMember = async (id) => jsonDelete(`team/member`, {id},true)
export const exportAllTeamMembers = async () => jsonGet('team/members/export')
export const exportAllTeamPaymentRecords = async () => jsonGet('team/payments/export')
export const downloadTeamDocument = async (id) => jsonGet(`team/document?documentId=${id}`)
export const getInviteLink = async member => jsonPost(`team/inviteExisting`, {member, returnLink: true},true)
export const sendInviteLink = async member => jsonPost(`team/inviteExisting`, {member},true)

export const setTeamJuryStatus = async (id,status,role) => jsonPatch('team/setJuryRoundStatus',{id, status,role}, true)

export const getArbitrators = async () => jsonGet('arbitrators')
export const getArbitrator = async () => jsonGet('arbitrator')
export const getArbitratorClaimantPairings = async () => jsonGet(`arbitrator/pairings?roleType=${TEAM_ROLE_TYPE.CLAIMANT}`)
export const getArbitratorRespondentPairings = async () => jsonGet(`arbitrator/pairings?roleType=${TEAM_ROLE_TYPE.RESPONDENT}`)
export const setArbitratorStatus = async (id,status,notify) => jsonPatch('arbitrator/status',{id, status, notify}, true)
export const saveArbitratorRankings = async(rankings,roleType) => jsonPost('arbitrator/rankings',{rankings, roleType}, true)
export const uploadCriticalCommentsDocument = async (roleType,teamId,document) => jsonPost(`arbitrator/document`,{roleType,teamId, document})
export const saveJuryRankings = async(rankings,roleType) => jsonPost('arbitrator/juryRankings',{rankings, roleType}, true)
export const saveArbitratorProfile = async (id,profile) => jsonPatch('arbitrator/profile',{ id, profile}, true)
export const getJuryTeams = async (roleType) => jsonGet(`arbitrator/juryTeams?role=${roleType}`)
export const exportAllArbitratorConflicts = async() => jsonGet('admin/arbitratorPairingExport',true)
export const getArbitratorOralArgumentSchedule = async () => jsonGet('arbitrator/oralArgumentSchedule',true)

export const saveArbitratorAvailability = async availability => jsonPost('arbitrator/availability',availability,true)

export const getArbitrationDateTimes = async () => jsonGet('arbitrator/dateTimes')
export const uploadDocument = async (type,whichMoot,document,overrideCode) => jsonPost(`team/document`,{type,whichMoot, document,overrideCode})

export const runPairing = async () => jsonGet('admin/pairing')

export const getMemoRankings = async (role) => jsonGet(`admin/rankings?role=${role}`, true)
export const exportMemoRankings = async (role) => jsonGet(`admin/rankings/export?role=${role}`,true)
export const getMemoAssignments = async (role) => jsonGet(`admin/memoAssignments?role=${role}`, true)
export const getJuryMemoRankings = async (role) => jsonGet(`admin/juryRankings?role=${role}`, true)
export const getJuryMemoAssignments = async (role) => jsonGet(`admin/juryMemoAssignments?role=${role}`, true)
export const getOralArgumentSchedule = async (roundType) => jsonGet(`admin/oralArgumentSchedule?roundType=${roundType}`,true)
export const getOralArgumentScheduleForPairing = async id => jsonGet(`admin/oralArgumentSchedule?pairingId=${id}`,true)

export const savePairing = async (pairing) => jsonPatch(`admin/pairing`,pairing,true)
export const saveScores = async (pairingId,scores) => jsonPost(`admin/scores`,{ id : pairingId, scores},true)
export const saveRoundWinners = async (roundWinners) => jsonPost(`admin/roundWinners`,{ roundWinners },true)

export const getRounds = async () => jsonGet('admin/rounds',true)
export const pairRound = async (roundType,scores) => jsonPost(`admin/pairRound`,{ roundType, scores },true)
export const pairWinnerRound = async (roundType,scores) => jsonPost(`admin/pairWinnerRound`,{ roundType },true)
export const publishRound = async roundType => jsonPost(`admin/publishRound`,{roundType},true)

export const getGlobals = async () => jsonGet('globals')

export const saveTeamRegistration = async (team,overrideCode) => jsonPost(`team`, {team,overrideCode},true)
export const saveArbitratorRegistration = async (arbitrator,overrideCode) => jsonPost(`arbitrator`, {arbitrator,overrideCode},true)

export const createUserAccount = async (email,cognitoId) => jsonPost(`registration/signup`, {email,cognitoId},false)

export const saveTeamOrder = async (teamId,orderJSON, description, amount,type) => jsonPost(`team/payment`,{teamId: parseInt(teamId),orderJSON,  description, amount,type},true)
export const getTeamPaymentHistory = async (teamId) => jsonGet(`team/payment/history?teamId=${teamId}`,true)
export const generateTeamInvoice = async() => blobGet(`team/invoice`,true)
export const createStripePaymentIntent = async(amount) => jsonGet(`team/stripePaymentIntent?amount=${amount}`,true)

export const getMoot = async () => jsonGet(`moot`,false)
export const saveMoot = async (moot,schedule) => jsonPost(`moot`,{moot, schedule}, true)
export const activateMoot = async mootId => jsonPost(`activateMoot`,{mootId}, true)

export const updateArbitratorPairing = async updatedPairingData => jsonPatch(`admin/arbitratorPairing`,updatedPairingData,true)
export const saveArbitratorPairing = async newPairing => jsonPost(`admin/arbitratorPairing`,newPairing, true)
export const deleteArbitratorPairing = async payload => jsonDelete(`admin/arbitratorPairing`,payload, true)
export const publishArbitratorPairings = async role => jsonPost(`admin/publishArbitratorPairings`,{role},true)
export const getMemoAssignableTeams = async () => jsonGet(`admin/getMemoAssignableTeams`,true)
export const generateWrittenArbitratorPairings = async role => jsonPost(`admin/generateWrittenArbitratorPairings`,{role},true)
export const exportWrittenArbitratorPairings = async role => jsonGet(`admin/exportWrittenArbitratorPairings?role=${role}`,true)

export const addArbitratorToJuryRanking = async (arbitratorId,role) => jsonPatch(`arbitrator/addToJuryRanking`,{arbitratorId,role},true)
export const removeArbitratorFromJuryRanking = async (arbitratorId,role) => jsonPatch(`arbitrator/removeFromJuryRanking`,{arbitratorId,role},true)
export const publishArbitratorJurySelections = async (role) => jsonPatch(`moot/publishArbitratorJurySelections`,{role},true)

export const getDraftPairings = async () => jsonGet(`admin/getDraftPairings`,true)
export const generateDraftPairings = async () => jsonGet(`admin/generateOralRoundPairings`,true)
export const saveDraftPairings = async (draftPairings,onlyDraft = true) => jsonPost(`admin/saveDraftPairings`,{draftPairings, onlyDraft}, true)

