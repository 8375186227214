import _ from "lodash/fp";
import React from "react";
import DailyOpponents from "./DailyOpponents";

const TeamBySchedule = ({teams, monday,tuesday,wednesday,thursday}) => {
    const getOpponentWithRole = (teamId, dayGroup) => {
        const matches =  _.filter(m => m.claimant.id === teamId || m.respondent.id === teamId,dayGroup)
        if (matches) {
            let result = []
            for (const match of matches) {
                result.push(match.claimant.id === teamId ? {isRespondent: true, team: match.respondent} : {
                    isClaimant: true,
                    team: match.claimant
                })
            }
            return result
        }
    }

    const teamSchedule = _.map(t => {

        const mondayOpponents = getOpponentWithRole(t.team.id,monday)
        const tuesdayOpponents = getOpponentWithRole(t.team.id,tuesday)
        const wednesdayOpponents = getOpponentWithRole(t.team.id,wednesday)
        const thursdayOpponents = getOpponentWithRole(t.team.id,thursday)
        return {
            ...t,
            mondayOpponents,
            tuesdayOpponents,
            wednesdayOpponents,
            thursdayOpponents
        }
    },teams)
    return (
        <>
            {_.map(p => {
                return (
                    <div key={`TeamBySchedule-${p.team.id}`}>
                        <h4 style={{margin: '2.5rem 0 0.5rem'}}>{p.team.school}</h4>
                        <div className='draft-pairing-by-schedule-grid__row'>
                            <h5 style={{margin: '0 0 0.25rem'}}></h5>
                            <h5 style={{margin: '0 0 0.25rem'}}>Claimant</h5>
                            <h5 style={{margin: '0 0 0.25rem'}}>Respondent</h5>
                        </div>
                        <DailyOpponents opponents={p.mondayOpponents} school={p.team.school} day='Monday' />
                        <DailyOpponents opponents={p.tuesdayOpponents} school={p.team.school} day='Tuesday' />
                        <DailyOpponents opponents={p.wednesdayOpponents} school={p.team.school} day='Wednesday' />
                        <DailyOpponents opponents={p.thursdayOpponents} school={p.team.school} day='Thursday' />
                    </div>)
            }, teamSchedule)}
        </>

    )
}

export default TeamBySchedule