import _ from "lodash/fp";
import {isNotEmpty} from "../../../utils/funcUtils";
import React from "react";
import ValidatedText from "./ValidatedText";
import {getTeamsWithOpponents} from "./pairingUtils";

const TeamByJurisdiction = ({teams, monday,tuesday,wednesday,thursday, onStatusFail= _.noop, onValidationFailure=_.noop}) => {
    return (
        <>
            <div className='draft-pairing-by-jurisdiction-grid__row'>
                <h5 style={{textAlign:'right'}}>#</h5>
                <h5 style={{paddingLeft:'0.5rem'}}>School</h5>
                <h5>Jurisdiction</h5>
                <h5>Mon</h5>
                <h5>Tue</h5>
                <h5>Wed</h5>
                <h5>Thur</h5>
                <h5>Status</h5>
            </div>
            {_.map(p => {
                const anyHaveSameJurisidiction = () => {
                    const team = p.team?.jurisdiction
                    const opponents =[..._.map('jurisdiction',p.mondayOpponents), ..._.map('jurisdiction',p.tuesdayOpponents),..._.map('jurisdiction',p.wednesdayOpponents),..._.map('jurisdiction',p.thursdayOpponents)]
                    const isInvalid = opponents.includes(team)
                    if (isInvalid) {
                        onStatusFail()
                    }
                    return isInvalid
                }
                const facingThreeOrMoreFromSameJurisdictions = () => {
                    const opponents =[..._.map('jurisdiction',p.mondayOpponents), ..._.map('jurisdiction',p.tuesdayOpponents),..._.map('jurisdiction',p.wednesdayOpponents),..._.map('jurisdiction',p.thursdayOpponents)]
                    const isInvalid = _.pipe(
                        _.groupBy(_.identity),
                        // The size check will return TRUE for any jurisdictions with a count of 3 or more
                        _.mapValues(x => _.size(x) >=3),
                        //Filter here is dropping all falsy values,
                        _.filter(x => x),
                        //So if there is anything left in the array at this point we failed validation (meaning we
                        // have a jurisdiction that shows up 3 or more times)
                        isNotEmpty
                    )(opponents)

                    if (isInvalid) {
                        onStatusFail()
                    }
                    return isInvalid
                }

                const isSameJurisidiction = teamJurisdiction => {
                    const team = p.team?.jurisdiction
                    if (teamJurisdiction === team) {
                        return true
                    }
                }
                const isPartOfMultipleOfTheSameJurisdiction = (teamJurisdiction,count) => {
                    const opponents =[..._.map('jurisdiction',p.mondayOpponents), ..._.map('jurisdiction',p.tuesdayOpponents),..._.map('jurisdiction',p.wednesdayOpponents),..._.map('jurisdiction',p.thursdayOpponents)]
                    const jurisdiction = _.pipe(
                        _.countBy(_.identity),
                        _.mapValues(x => x === count),
                    )(opponents)
                    const keys = Object.keys(jurisdiction)
                    const matches = _.filter(f =>  jurisdiction[f] === true,keys)

                    if (matches?.includes(teamJurisdiction)) {
                        return true
                    }
                }

                const facingTwoFromSameJurisdictions = () => {
                    const opponents =[..._.map('jurisdiction',p.mondayOpponents), ..._.map('jurisdiction',p.tuesdayOpponents),..._.map('jurisdiction',p.wednesdayOpponents),..._.map('jurisdiction',p.thursdayOpponents)]
                    return _.pipe(
                        _.groupBy(_.identity),
                        // The size check will return TRUE for any jurisdictions with a count of 3 or more
                        _.mapValues(x => _.size(x) === 2),
                        //Filter here is dropping all falsy values,
                        _.filter(x => x),
                        //So if there is anything left in the array at this point we failed validation (meaning we
                        // have a jurisdiction that shows up 3 or more times)
                        isNotEmpty
                    )(opponents)
                }


                const ValidatedJurisdictionOpponent = ({opponents, jurisdiction}) => {
                    return _.map(opponent => (
                        <div key={`ValidatedJurisdictionOpponent-opponent-${opponent.id}`}>
                            <ValidatedText isInvalid={[
                                {isInvalid: () => jurisdiction === opponent.jurisdiction },
                                {isInvalid: () => isPartOfMultipleOfTheSameJurisdiction(opponent.jurisdiction,2), className: 'draft-pairing-warning-text'},
                                {isInvalid: () => isPartOfMultipleOfTheSameJurisdiction(opponent.jurisdiction,3)},
                                {isInvalid: () => isSameJurisidiction(opponent.jurisdiction), className: 'draft-pairing-invalid-text'},
                            ]}
                                           onInvalid={(key) => onValidationFailure(p,key)}>
                                {opponent.jurisdiction}
                            </ValidatedText>
                        </div>
                    ),opponents)

                }
                return (
                    <div className='draft-pairing-by-jurisdiction-grid__row' key={`teamByJurisdiction-${p.team.id}`}>
                        <div style={{textAlign:'right'}}>{p.groupPosition}</div>
                        <div style={{paddingLeft:'0.5rem'}}>
                            <ValidatedText isInvalid={[
                                { isInvalid: anyHaveSameJurisidiction, validationFailureKey: 'facingSameJurisdictionAsSelf'},
                                { isInvalid: facingThreeOrMoreFromSameJurisdictions, validationFailureKey: 'facingThreeOrMoreFromSameJurisdictions'}
                            ]}
                                           onInvalid={(key) => onValidationFailure(p,key)}>
                                {p.team?.school}
                            </ValidatedText>
                        </div>
                        <div style={{borderRight:'1px dashed #eee'}}>
                            <ValidatedText isInvalid={{ isInvalid: anyHaveSameJurisidiction}}>
                                {p.team?.jurisdiction}
                            </ValidatedText>
                        </div>
                        <div>
                            <ValidatedJurisdictionOpponent opponents={p.mondayOpponents} jurisdiction={p.jurisdiction} />
                        </div>
                        <div>
                            <ValidatedJurisdictionOpponent opponents={p.tuesdayOpponents} jurisdiction={p.jurisdiction} />
                        </div>
                        <div>
                            <ValidatedJurisdictionOpponent opponents={p.wednesdayOpponents} jurisdiction={p.jurisdiction} />
                        </div>
                        <div>
                            <ValidatedJurisdictionOpponent opponents={p.thursdayOpponents} jurisdiction={p.jurisdiction} />
                        </div>
                        <div className={(anyHaveSameJurisidiction() || facingThreeOrMoreFromSameJurisdictions()) ? 'draft-pairing-invalid-text': 'draft-pairing-valid-text'}>{(anyHaveSameJurisidiction() || facingThreeOrMoreFromSameJurisdictions()) ? 'Fail' : 'Pass'}</div>
                    </div>
                )}, getTeamsWithOpponents(teams, monday,tuesday,wednesday,thursday))
            }
        </>)
}

export default TeamByJurisdiction