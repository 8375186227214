import {format, parseISO} from "date-fns";
import React, {useState, useEffect}  from "react";
import _ from "lodash/fp";
import { withStyles, Tooltip, Typography } from '@material-ui/core';
import { utils, writeFile } from 'xlsx'

export const getHoursDifference = () => {
  const localTime = new Date()
  //time zone value for Hong Kong
  const timeZoneFromDB = +8.0
  //get the timezone offset from local time in minutes
  const tzDifference = timeZoneFromDB * 60 + localTime.getTimezoneOffset()
  //convert the offset to milliseconds, add to localTime, and make a new Date
  const hongKongTime = new Date(localTime.getTime() + tzDifference * 60 * 1000)

  const hoursDifferenceBetweenHk = Math.abs(hongKongTime - localTime) / 36e5
  return hoursDifferenceBetweenHk
}

export const getFormattedTimeDifference = (hours, thirty) => {
  const hoursDifference = getHoursDifference()
  const date = new Date()
  date.setHours(hours - hoursDifference)
  const finalHours = date.getHours()
  if (finalHours === 0) return '12:00 AM'
  if (finalHours > 12) return finalHours - 12 + (thirty ? ':30 PM' : ':00 PM')
  return finalHours + (thirty ? ':30 AM' : ':00 AM')
}


export const clarificationGridColumnDefinition = (addTeam = false) => {

 let columnDef = [
  {
    field: 'category',
    headerName: 'Category',
    flex: 1
  },
  {
    field: 'clarificationSought',
    headerName: 'Clarification Sought',
    flex: 1
  },
  {
    field: 'rationale',
    headerName: 'Rationale',
    flex: 1
  },
  {
    field: 'createdAt',
    headerName: 'Date Submitted',
    width: 120,
    renderCell: params => format(parseISO(params.row.createdAt),'yyyy-MM-dd'),
    valueGetter: params => format(parseISO(params.row.createdAt),'yyyy-MM-dd HH:mm O')
  }
  ]

  if (addTeam){

    columnDef = [ {
      field: "",
      headerName: "School",
      flex : 1,
      valueGetter: params => params.row.team.school
    },...columnDef]
  }
  return columnDef
}


export const friendlyRoundName = roundType =>{
    switch (roundType){
        case 'General Rounds':
            return 'General Round'
        case 'Elimination Rounds: Round of 32':
            return 'Round of 32'
        case 'Elimination Rounds: Round of 16':
            return 'Round of 16'
        case 'Elimination Rounds: Quarter-Finals':
            return 'Quarterfinal'
        case 'Elimination Rounds: Semi-Finals':
            return 'Semifinal'
        case 'Elimination Rounds: Finals':
            return 'final'
        default:
            return roundType
    }
}

export const asHKCurrency = (value, fractionDigits = 2) =>{
    const valueLocaleString = value.toLocaleString('zh-HK',{style: 'currency', currency: 'HKD',minimumFractionDigits: fractionDigits, maximumFractionDigits: fractionDigits})
    const currencyWithSpace = valueLocaleString.split("$").join("$ ");
    return currencyWithSpace
}

export const formatCurrency = (value, prependText) => {
    const formattedValue = asHKCurrency(Math.abs(value))
    return <><span>{prependText} </span><span className={value < 0 ? 'negative-value' : 'positive-value'}>{value < 0 ? `(${formattedValue})` : `${formattedValue}`}</span></>

}

export const clientsideDownloadFile = (blob,fileName) => {
        // Test download attribute first
        // https://github.com/eligrey/FileSaver.js/issues/193
        if ('download' in HTMLAnchorElement.prototype) {
            // Create an object URL for the blob object
            const url = URL.createObjectURL(blob);

            // Create a new anchor element
            const a = document.createElement('a');
            a.href = url;
            a.download = fileName;

            // Programmatically trigger a click on the anchor element
            // Useful if you want the download to happen automatically
            // Without attaching the anchor element to the DOM
            a.click();

            // https://github.com/eligrey/FileSaver.js/issues/205
            setTimeout(() => {
                URL.revokeObjectURL(url);
            });
        }
}

// Creates an excel file from tab delimited data
export const buildFile = (data,filename)  => {
  // Extract headers from the first line
  const headers = data[0].split("\t");

  // Process the rest of the lines
  const jsonObject = data.slice(1).map(row => {
    // Normalize the row by replacing double `\t` with `\t \t` to handle empty values
    const normalizedRow = row.replace(/\t\t/g, "\t \t");
    
    // Split by `\t` and trim spaces
    const values = normalizedRow.split("\t").map(value => value.trim());
    
    return headers.reduce((obj, header, index) => {
      obj[header] = values[index] || null; // Use null for missing values
      return obj;
    }, {});
  });

  //creates an empty workbook with no worksheet
  let wb = utils.book_new(), //creates a new workbook

  //coverts JSON data into a sheet
  ws = utils.json_to_sheet(jsonObject);

  utils.book_append_sheet(wb, ws, "Sheet1")

  const fullName = `${filename} - ${format(new Date(),'yyyy-MM-dd')}.xlsx`;

  // package and release data (`writeFile` tries to write and save an XLSX file)      
  writeFile(wb, fullName)
}


const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    maxWidth: 180,
    userSelect: 'text',
  },
}))(Tooltip);

export const CustomTooltip = ({ content, tooltipContent = content }) => {
  return (
    <HtmlTooltip
      title={
        <React.Fragment>
          <Typography color="inherit" style={{ marginBottom: 0, fontWeight: 'bold', fontSize: '0.875rem' }}>
            {tooltipContent}
          </Typography>
        </React.Fragment>
      }
      interactive
      enterTouchDelay={0}
      arrow
      html="true"
    >
      <div>
        {content}
      </div>
    </HtmlTooltip>
  );
};


export const shouldOverride = (code,currentMoot) => {
    return !_.isNil(code) && code === currentMoot?.openOverrideCode
}

/* Local Storage */
export const getStorageValue = (key, defaultValue) => {
  // getting stored value
  const saved = localStorage.getItem(key);
  const initial = JSON.parse(saved);
  return initial || defaultValue;
}

export const useLocalStorage = (key, defaultValue) => {
  const [value, setValue] = useState(() => {
    return getStorageValue(key, defaultValue);
  });

  useEffect(() => {
    // storing input name
    localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue];
};