import _, {find} from 'lodash/fp'
import {connect} from "react-redux";
import {getDraftPairings} from '../../../reducers/adminSelectors'
import {generateDraftPairings, saveDraftPairings, saveRealPairings} from '../../../actions/adminActions'
import React, {useEffect, useState} from "react";
import Select from "../../common/Select";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Container
} from "@mui/material";
import {FormControl, Button, CircularProgress, Link, alpha} from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import './DraftPairing.css'
import {isNotEmpty} from "../../../utils/funcUtils";
import Grid from "@mui/material/Grid";
import {ExpandMore} from "@mui/icons-material";
import Typography from "@material-ui/core/Typography";
import {Tooltip} from "@material-ui/core";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import {getCurrentMoot} from "../../../reducers/mootSelectors";
import ordinal from "ordinal-number-suffix";
import TeamSwapper from "./TeamSwapper";
import GroupSummary from "./GroupSummary";
import DayGrid from "./DayGrid";
import TeamByJurisdiction from "./TeamByJurisdiction";
import TeamByDay from "./TeamByDay";
import TeamBySchedule from "./TeamBySchedule";

const DraftPairings = ({ draftPairings =[],
                           currentMoot,
                           getDraftPairings = _.noop,
                           generateDraftPairings = _.noop,
                           getCurrentMoot = _.noop,
                           saveDraftPairings = _.noop,
                           saveRealPairings = _.noop}) => {

    const [isLoading, setIsLoading] = useState(false)
    const [isSaving, setIsSaving] = useState(false)

    const [modifiedDraftPairings, setModifiedDraftPairings] = useState(draftPairings)
    const [activeLetter, setActiveLetter] = useState('')

    const [validationFailures,setValidationFailures] = useState([])
    const [knownValidationFailures,setKnownValidationFauilres] = useState([])

    useEffect(() => {
        async function getData() {
            try {
                setIsLoading(true)
                await getCurrentMoot()
                const pairings = await getDraftPairings();
                setModifiedDraftPairings(pairings)
            } finally {
                setIsLoading(false)
            }
        }

        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onValidationFailure = (e, key, letter) => {
        if (key) {
            //Keep track of the validation issue
            const existing = _.find(v => v.school === e.team?.school && v.key === key && v.letter === letter?.toUpperCase(), validationFailures)
            if (!existing) {
                setValidationFailures([...validationFailures, {
                    school: e.team?.school,
                    key,
                    letter: letter?.toUpperCase()
                }])
            }
            if (!knownValidationFailures.includes(letter)) {
                setKnownValidationFauilres([...knownValidationFailures, letter])
            }
        }
    }

    const activeLetterGroup = _.filter(p => p.letterGroup === activeLetter, modifiedDraftPairings)

    const letters = _.map('letterGroup', _.uniqBy('letterGroup', modifiedDraftPairings)).sort()

    const claimants = _.map(a => ({
        team: a.claimant,
        groupPosition: a.claimantGroupPosition,
        letter: a.letterGroup
    }), modifiedDraftPairings)

    const respondents = _.map(a => ({
        team: a.respondent,
        groupPosition: a.respondentGroupPosition,
        letter: a.letterGroup
    }), modifiedDraftPairings)

    const claimantsAndRespondents = [...claimants, ...respondents]
    const allTeams = _.uniqBy(t => t.team.id, _.sortBy(['letter', 'groupPosition'], claimantsAndRespondents))
    const allTeamsForActiveLetter = _.uniqBy(t => t.team.id, _.sortBy(['groupPosition'], _.filter(f => f.letter === activeLetter, claimantsAndRespondents)))

    const monday = _.filter(a => a.pairingDay === 1, activeLetterGroup)
    const tuesday = _.filter(a => a.pairingDay === 2, activeLetterGroup)
    const wednesday = _.filter(a => a.pairingDay === 3, activeLetterGroup)
    const thursday = _.filter(a => a.pairingDay === 4, activeLetterGroup)

    const allTeamSummary = _.map(t => {

        const claimantCount = _.sumBy(m => m.claimant.id === t.team.id, activeLetterGroup)
        const respondentCount = _.sumBy(m => m.respondent.id === t.team.id, activeLetterGroup)

        const mondayCount = _.sumBy(m => m.claimant.id === t.team.id || m.respondent.id === t.team.id, monday)
        const tuesdayCount = _.sumBy(m => m.claimant.id === t.team.id || m.respondent.id === t.team.id, tuesday)
        const wednesdayCount = _.sumBy(m => m.claimant.id === t.team.id || m.respondent.id === t.team.id, wednesday)
        const thursdayCount = _.sumBy(m => m.claimant.id === t.team.id || m.respondent.id === t.team.id, thursday)
        return {
            ...t,
            claimantCount,
            respondentCount,
            mondayCount,
            tuesdayCount,
            wednesdayCount,
            thursdayCount,
        }
    }, allTeamsForActiveLetter)

    const [teamByJurisdictionFail, setTeamByJurisdictionFail] = useState(false)
    const [teamByDayFail, setTeamByDayFail] = useState(false)

    const generatePairings = async (regenerate = false) => {
        let result = true;
        if (regenerate) {
            result = window.confirm(`Are you sure that you want to regenerate the draft pairings? This will replace the current draft pairings along with any changes that were made.`);
        }
        if (result) {
            try {
                setIsLoading(true)
                const pairings = await generateDraftPairings()
                setModifiedDraftPairings(pairings)
                setActiveLetter('a')
            } finally {
                setIsLoading(false)
            }
        }
    }

    const savePairings = async () => {
        const result = window.confirm(`Are you sure that you want to set the oral arguments? If the oral arguments have been previously set, this will replace them including all changes made in that section.`);
        if (result) {
            try {
                setIsSaving(true)
                const pairings = await saveRealPairings(modifiedDraftPairings)
                setModifiedDraftPairings(pairings)
            } finally {
                setIsSaving(false)
            }
        }
    }

    const resetValidations = () => {
        setValidationFailures([])
        setKnownValidationFauilres([])
        setTeamByDayFail(false)
        setTeamByJurisdictionFail(false)
    }

    const saveAsDraft = async () => {
        try {
            setIsSaving(true)
            await saveDraftPairings(modifiedDraftPairings)
        } finally {
            setIsSaving(false)
        }
    }

    const swapDayPairing = (dayList, direction, role, id) => {
        //direction is either up or down
        // id is the sourceId
        //figure out who is in the next spot for the given direction
        //swap id with the id below
        const sourcePairingIndex = _.findIndex(d => {
            if (role === 'claimant') {
                return d.claimant.id === id
            } else if (role === 'respondent') {
                return d.respondent.id === id
            }
        }, dayList)

        const sourceDayRecord = dayList[sourcePairingIndex]
        const sourceDraftPairingsRecordIndex = _.findIndex(d => d.id === sourceDayRecord.id,modifiedDraftPairings)

        const moveTeams = (role, sourceIndex,destinationIndex) => {
            const sourceDraftPairingsRecord = modifiedDraftPairings[sourceIndex]
            const destinationDraftPairingRecord = modifiedDraftPairings[destinationIndex]

            const cloneSource = _.clone(sourceDraftPairingsRecord)
            const cloneDestination = _.clone(destinationDraftPairingRecord)

            if (role === 'claimant') {
                //Move the claimant spots
                sourceDraftPairingsRecord.claimant = cloneDestination.claimant
                sourceDraftPairingsRecord.claimantId = cloneDestination.claimantId
                sourceDraftPairingsRecord.claimantGroupPosition = cloneDestination.claimantGroupPosition
                destinationDraftPairingRecord.claimant = cloneSource.claimant
                destinationDraftPairingRecord.claimantId = cloneSource.claimantId
                destinationDraftPairingRecord.claimantGroupPosition = cloneSource.claimantGroupPosition
            } else if (role === 'respondent') {
                sourceDraftPairingsRecord.respondent = cloneDestination.respondent
                sourceDraftPairingsRecord.respondentId = cloneDestination.respondentId
                sourceDraftPairingsRecord.respondentGroupPosition = cloneDestination.respondentGroupPosition
                destinationDraftPairingRecord.respondent = cloneSource.respondent
                destinationDraftPairingRecord.respondentId = cloneSource.respondentId
                destinationDraftPairingRecord.respondentGroupPosition = cloneSource.respondentGroupPosition
            }

            modifiedDraftPairings[sourceIndex] = sourceDraftPairingsRecord
            modifiedDraftPairings[destinationIndex] = destinationDraftPairingRecord
        }

        if (direction === 'down') {
            const afterDayRecord = dayList[sourcePairingIndex + 1]
            const afterDraftPairingsRecordIndex = _.findIndex(d => d.id === afterDayRecord.id, modifiedDraftPairings)
            moveTeams(role,sourceDraftPairingsRecordIndex,afterDraftPairingsRecordIndex)
        } else if (direction === 'up') {
            const beforeDayRecord = dayList[sourcePairingIndex -1]
            const beforeDraftPairingsRecordIndex = _.findIndex(d => d.id === beforeDayRecord.id, modifiedDraftPairings)
            moveTeams(role,sourceDraftPairingsRecordIndex,beforeDraftPairingsRecordIndex)
        }
        setModifiedDraftPairings([...modifiedDraftPairings])
        resetValidations()
    }

    const swapPairing = (sourceId,destinationId) => {

        //Find the destination team
        const sourceTeam = _.find(d => d.claimantId === sourceId,modifiedDraftPairings)?.claimant
        const destinationTeam = _.find(d => d.claimantId === destinationId,modifiedDraftPairings)?.claimant

        const newPairings = _.map(modifiedDraftPairing => {
            let update =_.clone(modifiedDraftPairing)
            if (modifiedDraftPairing.claimantId === sourceId) {
                update = {
                    ...modifiedDraftPairing,
                    claimant: destinationTeam,
                    claimantId: destinationId,
                }
            }else if (modifiedDraftPairing.respondentId === sourceId){
                update = {
                    ...modifiedDraftPairing,
                    respondent : destinationTeam,
                    respondentId: destinationId,
                }
            }

            if (modifiedDraftPairing.claimantId === destinationId) {
                update = {
                    ...update,
                    claimant: sourceTeam,
                    claimantId: sourceId,
                }
            }else if (modifiedDraftPairing.respondentId === destinationId) {
                update = {
                    ...update,
                    respondent: sourceTeam,
                    respondentId: sourceId,
                }
            }

            return update
        }, modifiedDraftPairings)

        setModifiedDraftPairings(newPairings)
        resetValidations()
    }

    const onValidationFailureHandler = (e,key) => {
        onValidationFailure(e,key,activeLetter)
    }
    const validationKeyMapping = {
        isFacingSameOpponentMoreThanOnce : 'is facing the same opponent more than once',
        facingSameJurisdictionAsSelf: 'is facing an opponent with the same jurisdiction',
        facingThreeOrMoreFromSameJurisdictions: 'is facing three or more opponents from the same jurisdiction',
        claimantCount: 'is not assigned as a claimant twice',
        respondentCount: 'is not assigned as a respondent twice',
        mondayCount: 'is assigned more than twice in a day ',
        tuesdayCount: 'is assigned more than twice in a day ',
        wednesdayCount: 'is assigned more than twice in a day ',
        thursdayCount: 'is assigned more than twice in a day ',
    }

    return (
        <Container className='draft-pairing-container' maxWidth='xl'>
            <Typography variant='h4' component='h1'>Draft Pairings for {isNotEmpty(currentMoot) ? `(${ordinal(currentMoot.mootNumber)} VEM)`: ''}</Typography>
            { isLoading === true &&
                <CircularProgress />
            }
            {isLoading === false &&  _.isEmpty(modifiedDraftPairings) &&
                // there is no pairing data
                <>
                    <Typography variant='body1' style={{margin:'1rem 0'}}>To help ensure proper pairings, please review each schools jurisdiction, legal system, and make any corrections prior to starting this process.</Typography>
                    <Button variant='contained' color='primary' onClick={() => generatePairings()}>Generate Pairings</Button>
                </>

            }
            { isLoading === false && isNotEmpty(modifiedDraftPairings) &&
                <>
                <Grid container spacing={2}>
                    <Grid container item xs={12} md={8} spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant='body1' style={{margin:'1rem 0'}}>Draft pairings were generated based on <strong>{allTeams.length} teams</strong> and separated into <strong>{letters.length} groups</strong>. Please review each group before setting the oral arguments. Please note that you can still edit the pairings after setting the oral arguments from within the oral arguments section.</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant='body1' style={{margin:'0'}}><strong>Select group letter to view pairings</strong></Typography>
                            <FormControl style={{width: '100%'}}>
                                <InputLabel>Group {_.upperCase(activeLetter)}</InputLabel>
                                <Select
                                onChange={e => {
                                    setActiveLetter(e.target.value)
                                    setTeamByJurisdictionFail(false)
                                    setTeamByDayFail(false)
                                    setValidationFailures([])
                                }}
                                options={_.map(l => ({name: _.upperCase(l), id: l}),letters)}
                            />
                            </FormControl>
                        </Grid>                        
                    </Grid>
                    <Grid item xs={12} md={4}>                       
                        <div className='draft-pairing-actions'>
                            <div>
                                <Typography variant='body1' style={{margin:'0'}}><strong>Overall Status:</strong> {knownValidationFailures.length < 1 && 
                                <span>Review each group for errors</span>}
                                </Typography>
                                
                                {_.map(x => (
                                        <div className='draft-pairing-invalid-text'>Issue in Group {x?.toUpperCase()}</div>
                                    )
                                    ,knownValidationFailures)
                                }
                            </div>
                            <div className='draft-pairing-save'>
                                <div style={{display: 'flex', gap: '4px'}}>
                                    <Button disabled={isSaving} variant='contained' onClick={() => saveAsDraft()}>Save Draft</Button>
                                    <Tooltip title='Save your changes while you continue to refine the pairings'>
                                        <InfoOutlinedIcon sx={{ fontSize: 18 }}/>
                                    </Tooltip>
                                 </div>
                                 <div style={{display: 'flex', gap: '4px'}}>
                                    <Button disabled={isSaving} variant='contained' onClick={() => savePairings()}>Save & Set Oral Arguments</Button>
                                    <Tooltip title='Save your changes and set the oral schedule based on these pairings. It will also assign the  claimant memos to the respondents. Respondents will see the claimant memo after the Respondent Memo Open date as passed. Please note that if the oral arguments were previously set, any changes to the oral arguments will be lost. This includes changes to the time, arbitrator assignments, etc.)'>
                                        <InfoOutlinedIcon sx={{ fontSize: 18 }}/>
                                    </Tooltip>
                                 </div>                                
                                {isSaving && <CircularProgress size={35}/>}
                                <div style={{display: 'flex', gap: '4px'}}>
                                    <Link disabled={isSaving} component='button' onClick={() => generatePairings(true)}>Start over</Link>
                                    <Tooltip title='This will regenerate the draft pairings'>
                                        <InfoOutlinedIcon sx={{ fontSize: 18 }}/>
                                    </Tooltip>
                                 </div>
                                
                            </div>

                        </div>
                    </Grid>
                    
                </Grid>
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                        <Typography variant='body1' style={{margin:'0'}}><strong>Group {activeLetter.toUpperCase()} Status:</strong></Typography>
                            { _.size(validationFailures) === 0 &&
                                <div>No failures found</div>
                            }
                            { _.size(validationFailures) > 0 &&
                                _.map(f => (
                                    <div className='draft-pairing-invalid-text'>{f.school} {validationKeyMapping[f.key]}</div>
                                ),validationFailures)

                            }
                        </Grid>
                        <Grid item xs={12}>
                           <TeamSwapper allTeams={allTeams} onSwap={(source,destination) => swapPairing(source,destination)}/>
                        </Grid>
                    </Grid>
                    
                    <div className='draft-pairing-group-grid' style={{margin:'1rem 0 3rem'}}>                        
                        <GroupSummary teamSummary={allTeamSummary} onValidationFailure={onValidationFailureHandler}/>
                    </div>
                    <div className='draft-pairing-day-grid' style={{marginBottom:'3rem'}}>                        
                        <Typography variant='h6' component='h3' style={{fontSize:'1rem', fontWeight:'bold'}}>GROUP SCHEDULE BY DAY</Typography>
                        <Typography>The schedule is broken down by each day of the oral general round. You can change the position of a team to help create a better pairing or to avoid conflicts. </Typography>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMore />} > Monday </AccordionSummary>
                            <AccordionDetails>
                                <DayGrid teams={monday} dayName='Monday' onSwap={(direction,role,id) => swapDayPairing(monday,direction,role,id)} />
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMore />}> Tuesday </AccordionSummary>
                            <AccordionDetails>
                                <DayGrid teams={tuesday} dayName='Tuesday' onSwap={(direction,role,id) => swapDayPairing(tuesday,direction,role,id)}/>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMore />}> Wednesday </AccordionSummary>
                            <AccordionDetails>
                                <DayGrid teams={wednesday} dayName='Wednesday' onSwap={(direction,role,id) => swapDayPairing(wednesday,direction,role,id)} />
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMore />}> Thursday </AccordionSummary>
                            <AccordionDetails>
                                <DayGrid teams={thursday} dayName='Thursday' onSwap={(direction,role,id) => swapDayPairing(thursday,direction,role,id)}/>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                    <div className='draft-pairing-recap' style={{marginBottom:'4rem'}}>
                    <Typography variant='h6' component='h3' style={{fontSize:'1rem', fontWeight:'bold'}}>GROUP {_.upperCase(activeLetter)} RECAP</Typography>
                        <Typography variant='h6' component='h4' style={{marginTop:'1rem', fontSize:'1rem', fontWeight:'bold'}} className={teamByJurisdictionFail ===  true ? 'draft-pairing-invalid-text' : ''}>Team By Jurisdiction</Typography>
                            <TeamByJurisdiction teams={allTeamsForActiveLetter} monday={monday} tuesday={tuesday} wednesday={wednesday} thursday={thursday}
                                                onValidationFailure={onValidationFailureHandler}
                                                onStatusFail={() => setTeamByJurisdictionFail(true)} />
                        <Typography variant='h6' component='h4' style={{borderTop:'1px solid gray', marginTop:'2rem', paddingTop:'2rem', fontSize:'1rem', fontWeight:'bold'}} className={teamByDayFail ===  true ? 'draft-pairing-invalid-text' : ''}>Team By Day</Typography>
                            <TeamByDay teams={allTeamsForActiveLetter} monday={monday} tuesday={tuesday} wednesday={wednesday} thursday={thursday}
                                       onValidationFailure={onValidationFailureHandler}
                                       onStatusFail={() => setTeamByDayFail(true)}/>
                        <Typography variant='h6' component='h4' style={{borderTop:'1px solid gray', marginTop:'2rem', paddingTop:'2rem', fontSize:'1rem', fontWeight:'bold'}}>Team By Schedule</Typography>
                        
                        <TeamBySchedule teams={allTeamsForActiveLetter} monday={monday} tuesday={tuesday} wednesday={wednesday} thursday={thursday}/>
                    </div>
                </>
            }
        </Container>
    )
}

export default connect(
    (state, ownProps) => ({
        draftPairings: state.admin.draftPairings,
        currentMoot: state.moot.currentMoot
    }), {
        getCurrentMoot: getCurrentMoot,
        getDraftPairings: getDraftPairings,
        generateDraftPairings: generateDraftPairings,
        saveDraftPairings : saveDraftPairings,
        saveRealPairings: saveRealPairings
    }
)(DraftPairings)