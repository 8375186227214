import _ from "lodash/fp";
import React from "react";

const ValidatedText = ({isInvalid = _.noop, onInvalid = _.noop, children}) => {

    if (Array.isArray(isInvalid)){
        //Then we have an array of validations to run, expecting each to have it's own class for failure?
        for (const validationObj of isInvalid) {
            const func = validationObj.isInvalid
            const overriddenClass = validationObj.className
            if (func){
                const invalid = func()
                if (invalid) {
                    if (invalid) {
                        onInvalid(validationObj.validationFailureKey)
                    }
                    return (
                        <span
                            className={overriddenClass ?? 'draft-pairing-invalid-text'}>{children}</span>
                    )
                }
            }
        }
        return (
            <span>{children}</span>
        )
    }else {
        const func = isInvalid?.isInvalid

        const invalid = func() === true

        if (invalid) {
            onInvalid(isInvalid.validationFailureKey)
        }

        return (
            <span className={invalid ? 'draft-pairing-invalid-text' : ''}>{children}</span>
        )
    }
}

export default ValidatedText