import _ from "lodash/fp";
import Typography from "@material-ui/core/Typography";
import React from "react";
import ValidatedText from "./ValidatedText";

const GroupSummary = ({teamSummary, onValidationFailure=_.noop}) => {
    const buildDayCountValidators = (count,validationFailureKey) => {
        return [
            { isInvalid: () => count === 0 || count === 2, className: 'draft-pairing-warning-text'},
            { isInvalid: () => count > 2, validationFailureKey}
        ]
    }

    const fourthBorderlines = [
        Math.floor(teamSummary.length * .25),
        Math.floor(teamSummary.length * .5),
        Math.floor(teamSummary.length * .75)
    ]

    const eighthBorderlines = [
        Math.floor(teamSummary.length * 0.125),
        Math.floor(teamSummary.length * 0.375),
        Math.floor(teamSummary.length * 0.625),
        Math.floor(teamSummary.length * 0.875)
    ]

    const isBorderlineFourth = index => {
        if(fourthBorderlines.includes(index)) {
            return true;
        }
    }

    const isBorderlineEighth = index => {
        if(eighthBorderlines.includes(index)) {
            return true;
        }
    }

    const isBorderlineNotDivisibleByEight = index => {

        if(teamSummary.length % 8 !== 0) {
            const borderline = Math.floor(teamSummary.length * .25) + 1;

            if(borderline === index) {
                return true;
            }
        }
    }

    const isDayCountFailed = (team, dayCountSum) => {
        return team.mondayCount > 2 || team.tuesdayCount > 2 || team.wednesdayCount > 2 || team.thursdayCount > 2 || dayCountSum > 4;
    }

    return (
        <>
            <Typography variant='h6' component='h2'>GROUP LIST</Typography>
            <div className='draft-pairing-group-grid__row'>
                <h5 className='draft-pairing-group-grid__row-head' style={{textAlign:'right'}}>#</h5>
                <h5 className='draft-pairing-group-grid__row-head' style={{paddingLeft:'0.5rem'}}>School</h5>
                <h5 className='draft-pairing-group-grid__row-head'>Jurisdiction</h5>
                <h5 className='draft-pairing-group-grid__row-head'>Legal System</h5>
                <h5 className='draft-pairing-group-grid__row-head' style={{textAlign:'center'}}>Claimant</h5>
                <h5 className='draft-pairing-group-grid__row-head' style={{textAlign:'center'}}>Respondent</h5>
                <h5 className='draft-pairing-group-grid__row-head' style={{textAlign:'center'}}>Mon</h5>
                <h5 className='draft-pairing-group-grid__row-head' style={{textAlign:'center'}}>Tue</h5>
                <h5 className='draft-pairing-group-grid__row-head' style={{textAlign:'center'}}>Wed</h5>
                <h5 className='draft-pairing-group-grid__row-head' style={{textAlign:'center'}}>Thur</h5>
                <h5 className='draft-pairing-group-grid__row-head' style={{textAlign:'center'}}>Compete Total</h5>
                <h5 className='draft-pairing-group-grid__row-head'>Status</h5>
            </div>
            {_.map((p, index) =>  {
                const dayCountSum = p.mondayCount + p.tuesdayCount + p.wednesdayCount + p.thursdayCount

                return (
                    <div className={'draft-pairing-group-grid__row ' +
                        (isBorderlineFourth(p.groupPosition) ? 'draft-pairing-group-grid__row-borderline-fourth ' : '') +
                        (isBorderlineEighth(p.groupPosition) ? 'draft-pairing-group-grid__row-borderline-eighth ' : '') +
                        (isBorderlineNotDivisibleByEight(p.groupPosition) ? 'draft-pairing-group-grid__row-borderline-not-divisible ' : '')} key={`groupSummary-${p.team.id}`}>
                        <div style={{textAlign:'right'}}>{p.groupPosition}</div>
                        <div style={{paddingLeft:'0.5rem'}}>{p.team?.school}</div>
                        <div>{p.team?.jurisdiction}</div>
                        <div>{p.team?.legalSystem}</div>
                        <div style={{textAlign:'center'}}><ValidatedText isInvalid={{isInvalid: () => p.claimantCount !== 2, validationFailureKey: 'claimantCount'}} onInvalid={(key) => onValidationFailure(p,key)}>{p.claimantCount}</ValidatedText></div>
                        <div style={{textAlign:'center'}}><ValidatedText isInvalid={{isInvalid: () => p.respondentCount !== 2, validationFailureKey: 'respondentCount'}} onInvalid={(key) => onValidationFailure(p,key)}>{p.respondentCount}</ValidatedText></div>
                        <div style={{textAlign:'center'}}>
                            <ValidatedText isInvalid={buildDayCountValidators(p.mondayCount,'mondayCount')}
                                           onInvalid={(key) => onValidationFailure(p,key)}>
                                {p.mondayCount}
                            </ValidatedText></div>
                        <div style={{textAlign:'center'}}>
                            <ValidatedText isInvalid={buildDayCountValidators(p.tuesdayCount,'tuesdayCount')}
                                           onInvalid={(key) => onValidationFailure(p,key)}>
                                {p.tuesdayCount}
                            </ValidatedText>
                        </div>
                        <div style={{textAlign:'center'}}>
                            <ValidatedText isInvalid={buildDayCountValidators(p.wednesdayCount,'wednesdayCount')}
                                           onInvalid={(key) => onValidationFailure(p,key)}>
                                {p.wednesdayCount}
                            </ValidatedText>
                        </div>
                        <div style={{textAlign:'center'}}>
                            <ValidatedText isInvalid={buildDayCountValidators(p.thursdayCount,'thursdayCount')}
                                           onInvalid={(key) => onValidationFailure(p,key)}>
                                {p.thursdayCount}
                            </ValidatedText>
                        </div>
                        <div style={{textAlign:'center'}}>{dayCountSum}</div>
                        <div className={isDayCountFailed(p, dayCountSum) ? 'draft-pairing-invalid-text': 'draft-pairing-valid-text'}>{isDayCountFailed(p, dayCountSum) ? 'Fail': 'Pass'}</div>
                    </div>
                )}, teamSummary)
            }
        </>)
}

export default GroupSummary