import _ from "lodash/fp";

const getOpponents = (teamId,dayGroup) => {
    const matches =  _.filter(m => m.claimant.id === teamId || m.respondent.id === teamId,dayGroup)
    if (matches) {
        const result = []
        for (const match of matches) {
            result.push(match.claimant.id === teamId ? match.respondent : match.claimant)
        }
        return result
    }
}

const getTeamsWithOpponents = (teams,monday,tuesday,wednesday,thursday) => {
    return _.map(t => {

        const mondayOpponents = getOpponents(t.team.id,monday)
        const tuesdayOpponents = getOpponents(t.team.id,tuesday)
        const wednesdayOpponents = getOpponents(t.team.id,wednesday)
        const thursdayOpponents = getOpponents(t.team.id,thursday)

        return {
            ...t,
            mondayOpponents,
            tuesdayOpponents,
            wednesdayOpponents,
            thursdayOpponents,
        }
    },teams)
}


export {
    getOpponents,
    getTeamsWithOpponents
};