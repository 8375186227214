import React, {useMemo} from 'react'
import {Button, Link, Typography, Tooltip} from '@material-ui/core'
import {DataGridPro, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarDensitySelector, GridToolbarExport, LicenseInfo, GridFooterContainer, GridFooter} from '@mui/x-data-grid-pro';
import {GridActionsCellItem} from '@mui/x-data-grid';
import {noop, join, isEmpty, last, get, filter, head, sumBy, find} from 'lodash/fp';
import {Delete, Payment} from '@mui/icons-material';
import { format, parseISO } from 'date-fns'
import {utcToZonedTime} from "date-fns-tz";
import {connect} from "react-redux";
import {getAllTeams} from "../../../reducers/adminSelectors";
import {setTeamStatus, exportAllTeamMembers,exportAllTeamPaymentRecords, downloadTeamDocument} from "../../../actions/adminActions";
import PropTypes from "prop-types";
import {SaveAlt} from "@material-ui/icons";
import {isNotEmpty} from "../../../utils/funcUtils";
import { buildFile, asHKCurrency, formatCurrency, useLocalStorage} from "../../helpers";
import {useHistory} from "react-router-dom";
import TeamName from 'components/team/TeamName';
import AdminTeamActionMenu from './AdminTeamActionMenu';

LicenseInfo.setLicenseKey(
    '19a99a1195c5f52fabd5f3310b083226T1JERVI6Mjk5MzAsRVhQSVJZPTE2NjQxNDA4NTIwMDAsS0VZVkVSU0lPTj0x',
);
const TeamsTable = ({ teams = [],
                      getTeams = noop,
                      setTeamStatus = noop,
                      exportAllTeamMembers = noop,
                      exportAllTeamPaymentRecords = noop,
                      downloadTeamDocument = noop}) => {

  const [busy, setBusy] = React.useState(false)
  const [totalAccepted, setTotalAccepted] = React.useState(0);
  const [teamColumns, setTeamColumns] = useLocalStorage("teamColumns", "");

  const history = useHistory()

  const onSetTeamStatus = async (id,status,notify) =>{
    try {
      setBusy(true)
      await setTeamStatus(id,status,notify)
      const allTeams = await getTeams(true)
      setTotalAccepted(calculateTotalAcceptedSum(allTeams))
      setBusy(false)
    }catch(e){
      //TODO ?
      setBusy(false)
    }

  }

  React.useEffect(() => {
    const asyncFetchData = async () => {
      try {
        setBusy(true)
        const allTeams = await getTeams()
        setBusy(false)
        setTotalAccepted(calculateTotalAcceptedSum(allTeams))       
      }catch(e){
        //TODO ?
        setBusy(false)
      }
    }
    asyncFetchData()
      // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const handleColumnVisibilityChange = (params) => {    
    setTeamColumns(params);
  };

  const isHidden = (keyValue, hideByDefault) => {
    if(keyValue === true ) { // Column is visible
      return false;
    } else if(keyValue === false ) { // Column is not visible
      return true;
    } else if(hideByDefault) { // Column is not visible by default
      return true;
    } else {
      return false; // Column is visible
    }
  }

  const exportTeamMembers = async () => {
    try {
      setBusy(true)
      const data = await exportAllTeamMembers()
      buildFile(data, 'Team Members')
    }finally {
      setBusy(false)
    }
  }

  const exportPaymentRecords = async () => {
    try {
      setBusy(true)
      const data = await exportAllTeamPaymentRecords()
      buildFile(data, 'Team Payment Records')
    }finally {
      setBusy(false)
    }
  }

  const downloadDocument = async (id,memoType,school) => {
    try {
      setBusy(true)
      const url = await downloadTeamDocument(id)
      const a = document.createElement('a')
      a.href = url
      a.download = `${school} - ${memoType}.pdf`
      a.click()
    }
    finally {
        setBusy(false)
    }
  }



  function CustomToolbar() {
    return (
        <GridToolbarContainer style={{gap: '15px' }}>
          <GridToolbarColumnsButton className="toolbar-button" />
          <GridToolbarFilterButton className="toolbar-button" />
          <GridToolbarDensitySelector className="toolbar-button" />          
          <Tooltip title='Export contains visible columns of the grid'>
            <GridToolbarExport
                  className="toolbar-button"
                  printOptions={{ disableToolbarButton: true }}
                  csvOptions={{
                      fileName: `Schools - ${format(new Date(),'yyyy-MM-dd')}`,
                      utf8WithBom: true,
                  }}                  
            />
          </Tooltip>
          <Button color="primary"
                size="small"
                startIcon={<SaveAlt />}
                aria-haspopup="menu"
                onClick={exportTeamMembers}>Export Team Members</Button>
          <Button color="primary"
                  size="small"
                  startIcon={<SaveAlt />}
                  aria-haspopup="menu"
                  onClick={exportPaymentRecords}>Export All Payment Records</Button>
        </GridToolbarContainer>
    );
  }

  function CustomFooter () {
    return (
      <GridFooterContainer style={{justifyContent: 'end'}}>
        <div style={{margin: '0px 16px'}}>Total Accepted Teams: {totalAccepted}</div>
        <GridFooter sx={{
          border: 'none', // To delete double border.
          }} />          
      </GridFooterContainer>
    );
  }

  const calculateTotalAcceptedSum = (data) => {    
    return filter(t=>t.status === 'accepted', data).length
  }

  const previousMootCountNumber =  (mootCount) => {
    if (mootCount === '1-5') {
      return (1)
    } else if (mootCount === '6-10') {
      return (6)
    } else if (mootCount === '11+') {
      return (11)
    } else {
      return mootCount
    }
  }

  const inPersonHeadCount = (members) => {
    let membersWillBeInPerson = members.filter(function (el) {
      return el.willBeInPerson
    });

    return membersWillBeInPerson.length
  }

  const onEditPayments = teamId => {
    history.push(`/admin/team/payments/${teamId}`)
  }

  const getAcceptWarning = (row) => {
    if (row.PercentageOfTeamsFromThisJurisdictionIfAccepted > 20 && row.status !== 'accepted') {
      return `Accepting this team will put ${row.jurisdiction} over 20%`
    }
  }

 
  const columns = useMemo(() => [
    {
        field: 'id',
        headerName: 'School Id',
        width: 90,
        align: 'right',
        hide: isHidden(teamColumns.id, true)
     },
     {
        field: 'school',
        headerName: 'School',
        flex: 1,
        renderCell: params => <TeamName team={params.row} adminUse={true} />,
        valueGetter: params => (params.row.school),
        hide: isHidden(teamColumns.school)
     },
     {
      field: 'street',
      headerName: 'Street',
      flex: 1,
      hide: isHidden(teamColumns.street, true)
    },
    {
      field: 'unit',
      headerName: 'Unit',
      flex: 1,
      hide: isHidden(teamColumns.unit, true)
    },
      {
        field: 'city',
        headerName: 'City',
        flex: 1,
        hide: isHidden(teamColumns.city)
      },
      {
        field: 'state',
        headerName: 'State',
        flex: 1,
        hide: isHidden(teamColumns.state, true)
      },
      {
        field: 'zip',
        headerName: 'Postal Code',
        width: 100,
        hide: isHidden(teamColumns.zip, true)
      },
      {
        field: 'jurisdiction',
        headerName: 'Jurisdiction',
        flex: 1,
        hide: isHidden(teamColumns.jurisdiction)
      },
      {
        field: 'PercentageOfTeamsFromThisJurisdiction',
        headerName: 'Jurisdiction Percentage',
        flex: 1,
        hide: isHidden(teamColumns.PercentageOfTeamsFromThisJurisdiction, true),
        align: 'right',
        renderCell: params => `${params.row.PercentageOfTeamsFromThisJurisdiction}%`,
        valueGetter: params => (params.row.PercentageOfTeamsFromThisJurisdiction)        
      },
      {
        field: 'PercentageOfTeamsFromThisJurisdictionIfAccepted',
        headerName: 'Jurisdiction Percentage If Accepted',
        flex: 1,
        hide: isHidden(teamColumns.PercentageOfTeamsFromThisJurisdictionIfAccepted, true),
        align: 'right',
        renderCell: params => `${params.row.PercentageOfTeamsFromThisJurisdictionIfAccepted}%`,
        valueGetter: params => (params.row.PercentageOfTeamsFromThisJurisdictionIfAccepted)
      },
      {
        field: 'legalSystem',
        headerName: 'Legal System',
        flex: 1,
        hide: isHidden(teamColumns.legalSystem, true),
      },
      {
        field: 'previousMootCount',
        headerName: 'Previous Moots',
        width: 120,
        type: 'number',
        hide: isHidden(teamColumns.previousMootCount, true),
        valueGetter: params => previousMootCountNumber(params.row.previousMootCount),
        renderCell: params => params.row.previousMootCount,
      },
      {
        field: 'teamOwnerUserId',
        headerName: 'Contact Id',
        width: 100,
        hide: isHidden(teamColumns.teamOwnerUserId, true),
      },
      {
        field: 'contact',
        headerName: 'Contact',
        flex: 1,
        hide: isHidden(teamColumns.contact, true),
        valueGetter: params => params.row.registrantFirstName + ' ' + params.row.registrantLastName
      },
      {
        field: 'teamOwnerEmail',
        headerName: 'Email',
        flex: 1,
        hide: isHidden(teamColumns.teamOwnerEmail, true),
        renderCell: params => <a href={`mailto:${params.row.teamOwner.email}`}>{params.row.teamOwner.email}</a>,
        valueGetter: params => (params.row.teamOwner.email)
      },
      {
        field: 'registrantPhone',
        headerName: 'Phone',
        width: 100,
        hide: isHidden(teamColumns.registrantPhone, true)
      },
      {
        field: 'createdAt',
        headerName: 'Created',
        width: 100,
        hide: isHidden(teamColumns.createdAt, true),
        valueGetter: params => format(parseISO(params.row.createdAt),'yyyy-MM-dd')
      },
      {
        field: 'teamMembers',
        headerName: 'Going to HK Count',
        width: 140,
        hide: isHidden(teamColumns.teamMembers, true),
        align: 'right',
        valueGetter: params => inPersonHeadCount(params.row.members),
      },
      {
        field: 'claimantDoc',
        headerName: 'Claimant Memo',
        flex: 1,
        hide: isHidden(teamColumns.claimantDoc, true),
        renderCell: params => {
          const doc = head(filter(d => d.documentType === 'CLAIMANT',params.row.TeamDocuments))
          return doc ? <Link component='button' underline='always' onClick={async (e) => await downloadDocument(doc.id,'CLAIMAINT',params.row.school)}>download</Link> : <span></span>;
        },
        valueGetter: params => head(filter(d => d.documentType === 'CLAIMANT',params.row.TeamDocuments))
      },
      {
        field: 'claimantDocCreatedAt',
        headerName: 'Claimant Memo Created At HKT',
        flex: 1,
        hide: isHidden(teamColumns.claimantDocCreatedAt, true),     
        valueGetter: params => {
          const doc = head(filter(d => d.documentType === 'CLAIMANT',params.row.TeamDocuments))
          return doc ? format(utcToZonedTime(doc.createdAt,'Asia/Hong_Kong'), 'yyyy-MM-dd HH:mm') : '';
        },
      },
      {
        field: 'claimantDocUpdatedAt',
        headerName: 'Claimant Memo Updated At HKT',
        flex: 1,
        hide: isHidden(teamColumns.claimantDocUpdatedAt, true), 
        valueGetter: params => {
          const doc = head(filter(d => d.documentType === 'CLAIMANT',params.row.TeamDocuments))
          return doc ? format(utcToZonedTime(doc.updatedAt,'Asia/Hong_Kong'), 'yyyy-MM-dd HH:mm') : '';          
        },        
      },
      {
        field: 'respondentDoc',
        headerName: 'Respondent Memo',
        flex: 1,
        hide: isHidden(teamColumns.respondentDoc, true), 
        renderCell: params => {
          const doc = head(filter(d => d.documentType === 'RESPONDENT',params.row.TeamDocuments))
          return doc ? <Link component='button' underline='always' onClick={async (e) => await downloadDocument(doc.id,'RESPONDENT',params.row.school)}>download</Link> : <span></span>;
        },
        valueGetter: params =>  head(filter(d => d.documentType === 'RESPONDENT',params.row.TeamDocuments))

      },
      {
        field: 'respondentDocCreatedAt',
        headerName: 'Respondent Memo Created At HKT',
        flex: 1,
        hide: isHidden(teamColumns.respondentDocCreatedAt, true), 
        valueGetter: params => {
          const doc = head(filter(d => d.documentType === 'RESPONDENT',params.row.TeamDocuments))
          return doc ? format(utcToZonedTime(doc.createdAt,'Asia/Hong_Kong'), 'yyyy-MM-dd HH:mm') : '';
        },      
      },
      {
        field: 'respondentDocUpdatedAt',
        headerName: 'Respondent Memo Updated At HKT',
        flex: 1,
        hide: isHidden(teamColumns.respondentDocUpdatedAt, true), 
        valueGetter: params => {
          const doc = head(filter(d => d.documentType === 'RESPONDENT',params.row.TeamDocuments))
          return doc ? format(utcToZonedTime(doc.updatedAt,'Asia/Hong_Kong'), 'yyyy-MM-dd HH:mm') : '';          
        },        
      },
      {
        field: 'paymentStatus',
        headerName: 'Application Status',
        width: 150,
        valueGetter: params => {
            if (isEmpty(params.row.Payments) && params.row.paymentType !== 'bankTransfer') {
                return 'In progress'
            } else if (params.row.paymentType === 'bankTransfer') {
                const paymentHistory = params.row.PaymentHistory;
                /* Determine if there is a "bank transfer" transaction in the payment history for type "CREDIT" */
                const hasBankTransferTransaction = find(transaction => {
                  const description = transaction.description.toLowerCase();
                  if(description.includes("bank transfer:") && transaction.type === "CREDIT") {
                    return true;
                  }
                },paymentHistory);
                return hasBankTransferTransaction ? "Paid: Bank Transfer" : "Bank Transfer"
            } else if (isNotEmpty(params.row.Payments) && params.row.paymentType === 'paypal') {
                return 'Paid: PayPal'
            } else if (isNotEmpty(params.row.Payments) && params.row.paymentType === 'stripe') {
              return 'Paid: Stripe'
          }
        }
      },
      {
        field: 'balance',
        headerName: 'Financials',
        width: 190,
        align: 'right',
        hide: isHidden(teamColumns.balance), 
        renderCell: params => {
          const paymentHistory = params.row.PaymentHistory;
          const balance = get('balance',last(paymentHistory))
          //Sum the amount from the filtered transactions by type "INITIAL" or "ADDITIONAL" or "CREDIT" if the credit doesn't equal a team member removal/credit.
          const totalCreditAmount = sumBy(s => s.amount, filter((transaction) => 
            transaction.type === 'INITIAL' || 
            transaction.type === 'ADDITIONAL' ||
            (transaction.type === 'CREDIT' && 
            (transaction.description !== 'Additional team member removal' && transaction.description !== 'Additional team member credit')) 
            , 
            paymentHistory));         
          
          return <Button color="primary"
                            size="small"
                            style={{textAlign: 'right'}}
                            endIcon={<Payment />}                                                     
                            onClick={() => onEditPayments(params.row.id)}>
                              <Tooltip title={`Balance: ${asHKCurrency(balance)}, Received: ${asHKCurrency(totalCreditAmount)}`} >
                                <div style={{display:'flex', flexDirection:'column'}}>                                
                                  <Typography variant="body2" style={{ display: 'block', margin:'0', lineHeight:'1' }}>{formatCurrency(balance)}</Typography>
                                  <Typography variant="body2" style={{ display: 'block', margin:'0', lineHeight:'1', fontSize: '0.75rem' }}>Received: {asHKCurrency(totalCreditAmount)} </Typography>
                                </div>
                              </Tooltip>
                              </Button>
          
        },
        valueGetter: params => {
          const balance = get('balance',last(params.row.PaymentHistory))
          return balance;
        }
      },
      {
        field: 'status',
        headerName: 'Approval Status',
        width: 120,
        hide: isHidden(teamColumns.status), 
      },
      {
        field: 'hideTeamName',
        headerName: 'Hide team name',
        width: 120,
        hide: isHidden(teamColumns.hideTeamName, true), 
      },
      {
        field: 'action',
        headerName: 'Action',
        width: 70,
        align: 'right',
        sortable: false,
        hide: isHidden(teamColumns.action), 
        renderCell: (params) => (<AdminTeamActionMenu
          status={params.row.status} 
          onAccept={()=>onSetTeamStatus(params.id, 'accepted')} 
          onHold={()=>onSetTeamStatus(params.id, 'hold')} 
          onDeny={()=>onSetTeamStatus(params.id, 'denied')} 
          onSilentlyHold={()=>onSetTeamStatus(params.id, 'hold', false)} 
          onSilentlyDeny={()=>onSetTeamStatus(params.id, 'denied', false)}
          acceptWarning={getAcceptWarning(params.row)}          
          />)
      },
      {
        field: 'delete',
        hide: isHidden(teamColumns.delete, true), 
        type: "actions",
        headerName: 'Delete',
        getActions: params => ([<GridActionsCellItem
            icon={
              <Tooltip title='Delete'>
                <Delete/>
              </Tooltip>
            }
            onClick={() => {
              const result = window.confirm(`Are you sure you want to delete ${params.row.school}? `);
              if (result) {
                onSetTeamStatus(params.row.id, 'delete')
              }
            }}
            label="Delete"/>])
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      ],[teams])

    return (
        <div style={{height: 700, width: '100%'}}>
          <DataGridPro
              loading={busy}
              rows={teams}
              columns={columns}
              onColumnVisibilityModelChange={handleColumnVisibilityChange}
              localeText={{ toolbarExport: 'Export Schools' }}
              components={{
                Toolbar: CustomToolbar,
                Footer: CustomFooter,
              }}
          />
        </div>
    )
  }



TeamsTable.propTypes = {
  teams: PropTypes.arrayOf(PropTypes.object),
  getTeams: PropTypes.func,
  setTeamStatus: PropTypes.func,
  exportAllTeamMembers: PropTypes.func,
  exportAllTeamPaymentRecords: PropTypes.func
}

export default connect(
    (state, ownProps) => ({
      teams: state.admin.teams
    }),{
      getTeams: getAllTeams,
      setTeamStatus: setTeamStatus,
      exportAllTeamMembers: exportAllTeamMembers,
      exportAllTeamPaymentRecords: exportAllTeamPaymentRecords,
      downloadTeamDocument: downloadTeamDocument
    })(TeamsTable)