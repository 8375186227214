import _, {find} from "lodash/fp";
import React, {useState} from "react";
import {Button, FormControl, Tooltip} from "@material-ui/core";
import {Autocomplete, TextField as MUITextField} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const TeamSwapper = ({allTeams, onSwap =_.noop})=> {
    const [showSwapList,setShowSwapList] = useState(false)
    const [sourceTeam,setSourceTeam] = useState(-1)
    const [destinationTeam,setDestinationTeam] = useState(-1)
    const [teamsLessSelected,setTeamsLessSelected] = useState([])

    const buildSwap = () => {
        onSwap(sourceTeam, destinationTeam)
        setSourceTeam()
        setDestinationTeam()
        setShowSwapList(false)
    }

    return (
        <>
            { showSwapList &&
                <div style={{width: '100%', maxWidth: '600px', border: '1px solid grey', borderRadius: '5px', padding: '1rem'}}>
                    <FormControl fullWidth>
                        <Autocomplete
                            value={sourceTeam || null}
                            onChange={(event,newValue) => {
                                setSourceTeam(newValue?.team.id)
                                setTeamsLessSelected(_.filter(t => t.team.id !== newValue?.team.id,allTeams))
                            }}
                            options={allTeams}
                            getOptionLabel={value => {
                                const match =  find(t => value?.team?.id ? t?.team?.id === value?.team?.id : value === t?.team?.id, allTeams)
                                if (match) {
                                    return `Group ${match?.letter.toUpperCase()} - #${match?.groupPosition} ${match?.team?.school}`
                                }else {
                                    return ''
                                }
                            }}
                            isOptionEqualToValue={(option, value) => value?.team?.id ? option?.team?.id === value?.team?.id : value === option?.team?.id}
                            renderInput={(params) => <MUITextField {...params} variant="standard" label="Swap" required/>}
                        />
                    </FormControl>
                    <FormControl fullWidth style={{marginTop: '10px'}}>
                        <Autocomplete
                            value={destinationTeam || null}
                            onChange={(event, newValue) => {setDestinationTeam(newValue?.team.id)}}
                            getOptionLabel={value => {
                                const match =  find(t =>  value?.team?.id ? t?.team?.id === value?.team?.id : value === t?.team?.id, allTeams)
                                if (match) {
                                    return `Group ${match?.letter.toUpperCase()} - #${match?.groupPosition} ${match?.team?.school}`
                                }else {
                                    return ''
                                }
                            }}
                            isOptionEqualToValue={(option, value) => {
                                return value?.team?.id ? option?.team?.id === value?.team?.id : value === option?.team?.id
                            }}
                            options={teamsLessSelected}
                            renderInput={(params) => <MUITextField {...params} variant="standard" label="With" required/>}
                        />
                    </FormControl>
                    <div style={{display:'flex', gap: '10px', marginTop: '20px'}}>
                        <Button variant='contained' color='primary' onClick={() => buildSwap()}>Swap</Button>
                        <Button variant='contained' onClick={() => {
                            setShowSwapList(false)
                            setDestinationTeam()
                            setSourceTeam()
                        }}>Cancel</Button>
                    </div>
                </div>
            }
            {
                !showSwapList &&
                <div style={{display: 'flex', gap: '4px'}}>
                    <Button variant='contained'
                            onClick={() => setShowSwapList(true)}>Swap Team</Button>
                    <Tooltip title='Swap teams within the current group list or across other group lists'>
                        <InfoOutlinedIcon sx={{ fontSize: 18 }}/>
                    </Tooltip>
                </div>

            }
        </>
    )
}

export default TeamSwapper

