import _ from "lodash/fp";
import React from "react";


const DailyOpponents = ({opponents, school, day}) => {
    return (
        _.map(opponent => (
            <div className='draft-pairing-by-schedule-grid__row'>
                <div>{day}</div>
                <div>{opponent?.isClaimant ? opponent?.team?.school : opponent !== undefined ?
                    <strong>{school}</strong> : <></>}</div>
                <div>{opponent?.isRespondent ? opponent?.team?.school : opponent !== undefined ?
                    <strong>{school} </strong> : <></>}</div>
            </div>
        ), opponents)
    )
}
export default DailyOpponents;