import {mapWithIndex} from "../../../utils/funcUtils";
import _ from "lodash/fp";
import React from "react";
import PairingDayRecord from "./PairingDayRecord";

const DayGrid = ({teams, dayName, onSwap}) => {

    return (
        <>
            <h3 style={{display:'none'}}>{dayName}</h3>
            <div className='draft-pairing-day-grid__row-grid'>
                <h5 className='draft-pairing-day-grid__row-head'>Action</h5>
                <h5 className='draft-pairing-day-grid__row-head' style={{textAlign: 'right'}}>#</h5>
                <h5 className='draft-pairing-day-grid__row-head' style={{paddingLeft: '0.5rem'}}>Claimant</h5>
                <h5 className='draft-pairing-day-grid__row-head'>Jurisdiction</h5>
                <h5 className='draft-pairing-day-grid__row-head'>Action</h5>
                <h5 className='draft-pairing-day-grid__row-head' style={{textAlign: 'right'}}>#</h5>
                <h5 className='draft-pairing-day-grid__row-head' style={{paddingLeft: '0.5rem'}}>Respondent</h5>
                <h5 className='draft-pairing-day-grid__row-head'>Jurisdiction</h5>
            </div>
            {mapWithIndex((pairing, idx) => (
                <PairingDayRecord index={idx} key={`pairingDayRecord-${pairing.id}`} pairing={pairing} groupSize={_.size(teams) * 2} onSwap={(direction,role,id) => onSwap(direction,role,id)}/>),teams)}
        </>
    )
}

export default DayGrid;