import React from 'react';
import { Document, Page, Image, Text, Link, View, Font, StyleSheet } from '@react-pdf/renderer';
import {get, getOr, last, map} from "lodash/fp";
import {asHKCurrency, formatCurrency} from "../helpers";
import {format, utcToZonedTime} from "date-fns-tz";
import OpenSans from "../../fonts/open-sans/static/OpenSans-Regular.ttf";
import OpenSansBold from "../../fonts/open-sans/static/OpenSans-Bold.ttf";


// PRO TIP: Use the REPL tool here https://react-pdf.org/repl to visually design this document
export const Invoice = ({team, transactions= [], isTeamMemberOpen= {}}) => {
    const styles = StyleSheet.create({
        body: {
            paddingTop: 25,
            paddingHorizontal: 25,
            paddingBottom: 65,
            fontFamily: 'OpenSans',
        },
        bodyText:{
            fontSize: 9,
            fontFamily: 'OpenSans',
            marginBottom: 5,            
        },
        boldText:{
            fontWeight: 'bold'
        },        
        image: {
            height: 95,
            width: 95,
            alignSelf: 'center',
            marginBottom: 6
        },
        subtitle: {
            fontSize: 12,
            marginBottom: 2,
            textAlign: 'center',
        },
        subtext:{
            fontSize: 9
        },
        box:{
            border: 1,
        },
        boxHeader:{
            padding: 2,
            fontSize: 10,
            textAlign: 'center',
            backgroundColor: '#dd1f26',
        },
        whiteText: {
            color : 'white'
        },
        footerText:{
            marginTop: 10,
            marginLeft: 15,
            fontSize: 9,
        },

    });
    const totalBalance = getOr(0,'balance',last(transactions))
    const date = format(new Date(),'dd LLL yyyy')
    const invoiceNumberDate = format(utcToZonedTime(get('createdAt',last(transactions)), 'Asia/Hong_Kong'),'yyyyMMdd')
    const isClosed = isTeamMemberOpen.closed

    Font.register({
        family: "OpenSans",
        fontStyle: "normal",
        fontWeight: "normal",
        fonts: [
          {
            src: OpenSans,
          },
          {
            src: OpenSansBold,
            fontWeight: "bold",
          },
        ],
      });

    const transactionsConsolidated = (transactions) => {

        const summary = {};

        // Array to store the final result
        const result = [];

        // Loop through each transaction
        transactions.forEach(transaction => {

            if (transaction.description === "Additional team member fee" || transaction.description === "Additional team member credit" || transaction.description === "Additional team member removal") {
                // Get the date in YYYY-MM-DD format
                const createdDate = format(utcToZonedTime(transaction.createdAt, 'Asia/Hong_Kong'),'yyyy-MM-dd')
                const date = new Date(createdDate).toISOString().split('T')[0];

                // If the date doesn't exist in the grouped object, initialize it
                if (!summary[date]) {
                    summary[date] = {
                        amount: 0,
                        balance: 0,
                        description: '',
                        createdAt: date,
                        type:'',
                        teamMemberChangeCount: 0
                    };
                }

                // Sum up the amounts for the transactions of the same date
                summary[date].amount += transaction.amount;

                // Balance for the transactions of the same date 
                summary[date].balance = transaction.balance;

                if(transaction.description === "Additional team member fee") {
                    summary[date].teamMemberChangeCount += 1;                   
                } else if (transaction.description === "Additional team member credit" || transaction.description === "Additional team member removal") {
                    summary[date].teamMemberChangeCount += -1;                    
                }

                const description = summary[date].teamMemberChangeCount < 0 ? "Additional team member removal" : "Additional team member fee";

                // Customize the description
                summary[date].description = `${Math.abs(summary[date].teamMemberChangeCount)} ${description}`;
                
            } else {
                // If the transaction doesn't match the grouping criteria, add it directly to the result
                result.push(transaction);
              }
        });

        // Filter the array to exclude objects with teamMemberChangeCount of 0
        const filteredSummary = Object.values(summary).filter(item => item.teamMemberChangeCount !== 0);

        // Convert the grouped object back into an array of combined transactions
        const combinedTransactions = [...result, ...Object.values(filteredSummary)]
        
        // Sort by createdAt
        combinedTransactions.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));

        return combinedTransactions;
    }

    const formatCurrencyForInvoice = (value) => {
        const formattedValue = asHKCurrency(value <= 0 ? Math.abs(value) : value * -1)        
        return <Text>{formattedValue}</Text>
    }

    const address = team.useSchoolAddressForBilling ?
    `${team.street}${team.unit ? ' '+team.unit+' ' : ' '}\n${team.city} ${team.state} ${team.zip}\n${team.jurisdiction}` :
    `${team.billingAddress1}${team.billingAddress2 ? ' '+team.billingAddress2+' ' : ' '}\n${team.billingCity} ${team.billingState} ${team.billingPostalCode}\n${team.billingJurisdiction}`

    return (
        <Document>
            <Page style={styles.body}>
                <Image
                    style={styles.image}
                    src="/logos/East-Vis-Moot-Logo-Red.png"
                />
                <Text style={[styles.subtitle,styles.boldText]}>
                    TWENTY-SECOND ANNUAL
                </Text>
                <Text style={[styles.subtitle,styles.boldText]}>
                    WILLEM C. VIS EAST INTERNATIONAL COMMERCIAL ARBITRATION MOOT
                </Text>
                <Text style={{textAlign: 'right', marginRight: 5, fontSize: 10, marginTop: 15}}>
                    {date}
                </Text>
                <Text style={[styles.boldText,{textAlign: 'center', fontSize: 10, marginTop: 6}]}>
                    INVOICE
                </Text>
                <Text style={[styles.bodyText, styles.boldText, {textAlign: 'right', marginRight: 5, fontSize: 10, marginTop: 6}]}>                    
                    Invoice #VEM-{team.id}-{invoiceNumberDate}
                </Text>
                <View style={[styles.boxHeader, { marginTop: 10, width: 250, textAlign: 'left'}]}>
                    <Text style={[styles.whiteText,styles.boldText]}>
                        Issued To:
                    </Text>
                </View>
                <View style={[styles.box,{ width: 250, padding: 4}]}>                   
                    <Text style={[styles.bodyText,styles.boldText]}>
                        {team.useSchoolAddressForBilling ? `${team.school}\n` : `${team.billingName}\n`}
                        {address}
                    </Text>
                    { team.billingReferenceCode &&
                        <Text style={[styles.bodyText,styles.boldText]}>
                            Reference Code: {team.billingReferenceCode}
                        </Text>
                    }
                </View>

                <View style={{alignSelf: 'center'}}>
                    <View style={{flexDirection: 'row'}}>
                        <View style={[styles.boxHeader, { marginTop: 20, width: 100}]}>
                            <Text style={[styles.whiteText,styles.boldText]}>
                                DATE
                            </Text>
                        </View>
                        <View style={[styles.boxHeader, { marginTop: 20, width: 285}]}>
                            <Text style={[styles.whiteText,styles.boldText]}>
                                DESCRIPTION
                            </Text>
                        </View>
                        <View style={[styles.boxHeader, { marginTop: 20, width: 80}]}>
                            <Text style={[styles.whiteText,styles.boldText]}>
                                AMOUNT
                            </Text>
                        </View>
                        <View style={[styles.boxHeader, { marginTop: 20, width: 80}]}>
                            <Text style={[styles.whiteText,styles.boldText]}>
                                BALANCE OWED
                            </Text>
                        </View>
                    </View>
                    
                    {(map(t => (                        
                        <View style={{flexDirection: 'row'}} key={format(utcToZonedTime(t.createdAt, 'Asia/Hong_Kong'),'yyyy-MM-dd-t')}>
                            <View style={[styles.box,{borderRight: 0,borderLeft: 1,borderTop: 0,borderBottom:1, width: 100, padding: 4}]}>
                                <Text style={[styles.bodyText,{marginBottom: 0}]}>
                                    <Text style={styles.boldText}>{format(utcToZonedTime(t.createdAt, 'Asia/Hong_Kong'),'dd MMMM yyyy')}</Text>
                                </Text>
                            </View>
                            <View style={[styles.box,{borderRight: 0,borderLeft: 1,borderTop: 0,borderBottom:1, width: 285, padding: 4}]}>
                                <Text style={[styles.bodyText,{marginBottom: 0}]}>
                                   
                                    <Text>{t.description}</Text>
                                </Text>
                            </View>
                            <View style={[styles.box,{borderRight: 0,borderLeft: 1,borderTop: 0,borderBottom:1, width: 80, padding: 4, textAlign: 'right'}]}>
                                <Text style={[styles.bodyText,{marginBottom: 0}]}>{formatCurrencyForInvoice(t.amount)}</Text>
                            </View>
                            <View style={[styles.box,{borderRight: 1,borderLeft: 1,borderTop: 0,borderBottom:1, width: 80, padding: 4, textAlign: 'right'}]}>
                                <Text style={[styles.bodyText,{marginBottom: 0}]}>{formatCurrencyForInvoice(t.balance)}</Text>
                            </View>
                        </View>                   
                        ), isClosed ? transactionsConsolidated(transactions) : transactions)
                    )}
                   
                    <View style={{flexDirection: 'row',textAlign: 'right'}}>
                        <View style={[styles.box,{borderRight: 0,borderLeft: 1,borderTop: 0,borderBottom:1, width: 465, padding: 4}]}>
                            <Text style={[styles.bodyText, styles.boldText,{marginBottom: 0}]}>TOTAL DUE:</Text>
                        </View>
                        <View style={[styles.box, {borderRight: 1,borderLeft: 1,borderTop: 0,borderBottom:1, width: 80, padding: 4}]}>
                            <Text style={[styles.bodyText, styles.boldText, {marginBottom: 0}]}>{formatCurrencyForInvoice(totalBalance)}</Text>
                        </View>
                    </View>
                </View>
                <View style={{marginTop: 10}}>
                    <Text style={styles.subtext}>Please remit payment via <Text style={{textDecoration: 'underline'}}>PayPal or Stripe (directly through your Team Account)</Text> or by <Text style={{textDecoration: 'underline'}}>Bank Transfer</Text> using the following instructions.</Text>
                    <Text style={styles.subtext}><Text style={styles.boldText}>Bank Transfer: </Text>For costs associated with your own bank, teams are responsible for contacting their bank directly</Text>
                </View>
                <View style={{alignSelf: 'center', marginTop: 5}}>
                    <View style={{flexDirection: 'row'}}>
                        <View style={[styles.boxHeader, { width: '50%'}]}>
                            <Text style={[styles.whiteText,styles.boldText]}>
                                PAYPAL SEND & REQUEST
                            </Text>
                        </View>
                        <View style={[styles.boxHeader, { width: '50%'}]}>
                            <Text style={[styles.whiteText,styles.boldText]}>
                                BANK TRANSFER
                            </Text>
                        </View>
                    </View>
                    <View style={{flexDirection: 'row'}}>
                        <View style={[styles.box,{width: '50%', padding: 5}]}>                            
                            <Text style={styles.bodyText}>
                                1. If you are experiencing problems with your Team Account, you can alternatively click on <Link href="https://www.paypal.com/myaccount/transfer/">PayPal Send & Request</Link>.
                            </Text>
                            <Text style={styles.bodyText}>
                                2. Enter info@cisgmoot.org and click 
                                <Text style={styles.boldText}> Next.</Text>
                            </Text>
                            <Text style={styles.bodyText}>
                               3. Type the amount under "Recipient gets" Add a note <Text style={styles.boldText}>"{team.school}"</Text> and click <Text style={styles.boldText}>Continue.</Text>
                            </Text>
                            <Text style={styles.bodyText}>
                                4. Choose your preferred payment method and tap <Text style={styles.boldText}>Next.</Text>
                            </Text>
                            <Text style={[styles.bodyText,{marginBottom: 0}]}>
                                5. Review the information and tap 
                                <Text style={styles.boldText}> Send now</Text>.
                            </Text>
                        </View>
                        <View style={[styles.box,{width: '50%', padding: 5}]}>
                            <Text style={styles.bodyText}>
                                <Text style={styles.boldText}>Beneficiary Name: </Text>
                                    Vis East Moot Foundation Ltd.                               
                            </Text>
                            <Text style={styles.bodyText}>
                                <Text style={styles.boldText}>Bank: </Text>
                                HSBC, 1 Queen’s Road Central, Hong Kong
                            </Text>
                            <Text style={styles.bodyText}>
                                <Text style={styles.boldText}>Account Number: </Text>
                                817 365901 001
                            </Text>
                            <Text style={styles.bodyText}>
                                <Text style={styles.boldText}>Swift Code: </Text>
                                HSBCHKHHHKH
                            </Text>
                            <Text style={styles.bodyText}>
                                <Text style={styles.boldText}>For local Hong Kong transfers, Bank Code: </Text>
                                004
                            </Text>
                            <Text style={styles.bodyText}>
                                <Text style={styles.boldText}>Beneficiary Address: </Text>
                                Room 106, 1/Floor, Justice Place West Wing, 11 Ice House Street, Central, Hong Kong
                            </Text>
                            <Text style={styles.bodyText}>
                                <Text style={styles.boldText}>Reference: </Text>
                                For {team.school}
                            </Text>
                        </View>
                    </View>
                </View>
                <View style={styles.footerText}>
                    <Text style={{marginBottom: 3}}>
                        1. As set out in 22nd Vis East Rules, the registration fee for the <Text style={styles.boldText}>22nd Vis East Moot of HK$10,000.00</Text> applies to teams comprised of 4 – 8 team members. Teams with fewer than 4 members may pay a reduced registration fee of <Text style={styles.boldText}>HK$9,500.00</Text>. Teams with more than 8 members pay the registration fee of <Text style={styles.boldText}>HK$10,000.00 <Text style={{textDecoration: 'underline'}}>plus</Text> HK$1,500.00</Text> for each additional member over 8 who is coming to Hong Kong.
                    </Text>
                    <Text style={{marginBottom: 3}}>
                        2. Please ensure that you reference your school’s name when you remit payment.
                    </Text>
                    <Text style={{marginBottom: 3}}>
                        3. For good order, and to ensure we are able to credit your team's account, please also send proof of payment to the Vis East Administration via email: <Link href="mailto:info@cisgmoot.org">info@cisgmoot.org</Link>.
                    </Text>
                    <Text style={{marginBottom: 3}}>
                        4. Payment of the outstanding balance of the registration fee <Text style={{textDecoration: 'underline'}}>including</Text> any associated handling fees is due by <Text style={[styles.boldText,{textDecoration: 'underline'}]}>13 December 2024</Text>, unless prior arrangements have been approved in writing by the Vis East administration. Otherwise, a team which has not paid by the deadline will be deemed to have withdrawn from the competition. Any team that has withdrawn following acceptance of its application will lose its non-refundable deposit.
                    </Text>
                    <Text>
                        5. The Rules of the 22nd Vis East Moot are available on the website: www.cisgmoot.org.
                    </Text>
                </View>
            </Page>
        </Document>
    )
}