import _ from "lodash/fp";
import React from "react";
import ValidatedText from "./ValidatedText";
import {getTeamsWithOpponents} from "./pairingUtils";

const TeamByDay = ({teams, monday, tuesday, wednesday, thursday, onStatusFail = _.noop, onValidationFailure=_.noop}) => {


    return (
        <>
            <div className='draft-pairing-by-day-grid__row'>
                <h5 style={{textAlign:'right'}}>#</h5>
                <h5 style={{paddingLeft:'0.5rem'}}>School</h5>
                <h5>Mon</h5>
                <h5>Tue</h5>
                <h5>Wed</h5>
                <h5>Thur</h5>
                <h5>Status</h5>
            </div>
            {_.map(p => {

                const isFacingSameOpponentMoreThanOnce = () =>{

                    const opponents = [..._.map('school',p.mondayOpponents),
                        ..._.map('school',p.tuesdayOpponents),
                        ..._.map('school',p.wednesdayOpponents),
                        ..._.map('school',p.thursdayOpponents)]
                    const unique = _.uniq(opponents)
                    if (_.size(opponents) !== _.size(unique)) {
                        onStatusFail()
                        return true
                    }
                    return false
                }

                const isPartOfMultipleOfTheSameSchool = (school) => {
                    const opponents = [..._.map('school',p.mondayOpponents),
                        ..._.map('school',p.tuesdayOpponents),
                        ..._.map('school',p.wednesdayOpponents),
                        ..._.map('school',p.thursdayOpponents)]

                    const schools = _.pipe(
                        _.countBy(_.identity),
                        _.mapValues(x => x > 1),
                    )(opponents)
                    const keys = Object.keys(schools)
                    const matches = _.filter(f =>  schools[f] === true,keys)

                    if (matches?.includes(school)) {
                        return true
                    }
                }

                const ValidatedOpponent = ({opponents}) => {
                    return _.map( opponent => (
                        <div key={`opponent-${opponent.id}`}>
                            <ValidatedText isInvalid={{ isInvalid: () => isPartOfMultipleOfTheSameSchool(opponent.school)}}>
                                {opponent.school}
                            </ValidatedText>
                        </div>
                    ),opponents)
                }
                return (
                    <div className='draft-pairing-by-day-grid__row' key={`draft-pairing-by-day-${p.team.id}`}>
                        <div style={{textAlign:'right'}}>{p.groupPosition}</div>
                        <div style={{paddingLeft:'0.5rem', borderRight:'1px dashed #eee'}}>
                            <ValidatedText isInvalid={{ isInvalid: isFacingSameOpponentMoreThanOnce, validationFailureKey: 'isFacingSameOpponentMoreThanOnce'}}
                                           onInvalid={key=> onValidationFailure(p,key)}>
                                {p.team?.school}
                            </ValidatedText>
                        </div>
                        <div>
                            <ValidatedOpponent opponents={p.mondayOpponents} />
                        </div>
                        <div>
                            <ValidatedOpponent opponents={p.tuesdayOpponents} />
                        </div>
                        <div>
                            <ValidatedOpponent opponents={p.wednesdayOpponents} />
                        </div>
                        <div>
                            <ValidatedOpponent opponents={p.thursdayOpponents} />
                        </div>
                        <div className={isFacingSameOpponentMoreThanOnce() ? 'draft-pairing-invalid-text': 'draft-pairing-valid-text'}>{isFacingSameOpponentMoreThanOnce() ? 'Fail': 'Pass'}</div>
                    </div>
                )}, getTeamsWithOpponents(teams,monday,tuesday,wednesday,thursday))
            }
        </>)
}

export default TeamByDay