import Container from '@material-ui/core/Container'
import React, {useEffect} from 'react'
import {Typography} from '@material-ui/core'
import MemoAssignmentsTable from './MemoAssignmentsTable'
import MemoJuryAssignmentsTable from './MemoJuryAssignmentsTable'
import { makeStyles } from '@material-ui/core/styles'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import TabPanel from '../../common/TabPanel'
import { TEAM_ROLE_TYPE } from "../../../utils/constants";
import {connect} from "react-redux";
import {getAllArbitrators, getAllMemoAssignments, getMemoAssignableTeams} from "../../../reducers/adminSelectors";
import {saveArbitratorPairing} from "../../../actions/adminActions";
import {useHistory} from "react-router-dom";

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
}))

const AdminMemoAssignments = ({router}) => {

  const classes = useStyles()
  const [tabValue, setTabValue] = React.useState(0)
  const history = useHistory()

  const handleTabChange = (event, newValue) => {
      setTabValue(newValue)
      if (newValue === 1)
      {
          history.push(`/admin/memoAssignments?role=${TEAM_ROLE_TYPE.RESPONDENT}`)
      }else {
          history.push(`/admin/memoAssignments?role=${TEAM_ROLE_TYPE.CLAIMANT}`)
      }

  }

    useEffect(() => {
        if (router?.location.query.role === TEAM_ROLE_TYPE.RESPONDENT){
            setTabValue(1)
        }
    }, []);


  return (
      <Container maxWidth='xl' className={classes.root}>
        <Typography variant='h4'>Memo Assignments</Typography>
        <Tabs value={tabValue} onChange={handleTabChange}>
          <Tab label='Claimant Assignments'  />
          <Tab label='Respondent Assignments' />
          <Tab label='Jury Claimant Assignments' />
          <Tab label='Jury Respondent Assignments' />
        </Tabs>
          <TabPanel value={tabValue} index={0}>
              <MemoAssignmentsTable role={TEAM_ROLE_TYPE.CLAIMANT} />
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
              <MemoAssignmentsTable role={TEAM_ROLE_TYPE.RESPONDENT} />
          </TabPanel>
          <TabPanel value={tabValue} index={2}>
              <MemoJuryAssignmentsTable role={TEAM_ROLE_TYPE.CLAIMANT} />
          </TabPanel>
          <TabPanel value={tabValue} index={3}>
              <MemoJuryAssignmentsTable role={TEAM_ROLE_TYPE.RESPONDENT} />
          </TabPanel>
      </Container>
  )
}


export default connect(
    (state, ownProps) => ({
        router: state.router,
        ...ownProps
    }))(AdminMemoAssignments)